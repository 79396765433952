import UserStore from '../stores/user-store';
import UsersStore from '../stores/users-store';

export default (WrappedComponent) => {
    class HOC extends React.Component {
        static displayName = 'withUser';

        constructor(props) {
            super(props);
            ES6Component(this);
            this.listenTo(UserStore, 'change', () => {
                this.setState({
                    user: { ...(UserStore.getUser(this.props.id) || {}) },
                    userLoading: UserStore.isLoading,
                    userSaving: UserStore.isSaving,
                });
            });
            this.state = {
                user: { ...(UserStore.getUser(this.props.id) || {}) },
                userLoading: UserStore.isLoading,
                userSaving: UserStore.isSaving,
            };
        }

        componentDidMount() {
            if (this.wrappedComponent.onSave) {
                this.listenTo(UserStore, 'saved', () => this.wrappedComponent.onSave(UserStore.getUser()));
                this.listenTo(UsersStore, 'saved', () => this.wrappedComponent.onSave(_.last(UsersStore.getUsers())));
            }
            if (this.wrappedComponent.onDelete) {
                this.listenTo(UsersStore, 'deleted', this.wrappedComponent.onDelete);
            }
        }

        setUserProperty = (path, e) => {
            const user = this.state.user;
            user[path] = Utils.safeParseEventValue(e);
            this.setState({ user });
        }

        saveUser = (e) => {
            Utils.preventDefault(e);
            if (this.props.id) {
                AppActions.updateUser(this.state.user);
            } else {
                AppActions.createUser(this.state.user);
            }
        }

        deleteUser = (e) => {
            Utils.preventDefault(e);
            AppActions.deleteUser(this.state.user.id);
        }

        saveUserPassword = (e) => {
            Utils.preventDefault(e);
            AppActions.updateUserPassword(this.state.user);
        }

        render() {
            const { user, userLoading, userSaving } = this.state;
            const { setUserProperty, deleteUser, saveUser, saveUserPassword } = this;
            return (
                <WrappedComponent
                  ref={c => this.wrappedComponent = c}
                  user={user}
                  setUserProperty={setUserProperty}
                  saveUser={saveUser}
                  deleteUser={deleteUser}
                  saveUserPassword={saveUserPassword}
                  userLoading={userLoading}
                  userSaving={userSaving}
                  {...this.props}
                />
            );
        }
    }

    return HOC;
};