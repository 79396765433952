/**
 * Created by kylejohnson on 21/08/2016.
 */
import UsersTable from '../UsersTable';
import withUsers from '../../../common/providers/withUsers';

const UsersPage = class extends React.Component {
    displayName = 'UsersPage';

    componentWillMount() {
        AppActions.getUsers();
    }

    render() {
        const { props: { users, usersLoading } } = this;
        return (
            <div className="mt-5">
                <div className="card card--report">
                    <ul className="nav justify-content-end">
                        <li className="nav-item nav-link" data-toggle="modal" data-target="#createReportModal">
                            <Link to="/admin/users/create">
                                <i
                                  className="fas fa-file" data-toggle="tooltip" data-placement="top"
                                  title="Create User"
                                />
                            </Link>
                        </li>
                    </ul>
                    <UsersTable usersLoading={usersLoading} users={users}/>
                </div>
            </div>
        );
    }
};

export default hot(module)(withUsers(UsersPage));
