import React from 'react';
import withWhitelabel from '../../../common/providers/withWhitelabel';

export default hot(module)(withWhitelabel(class extends React.Component {
  static contextTypes = {
      router: propTypes.object.isRequired,
  };

  static displayName = 'HomePage';

  state = {
      username: '',
      password: '',
  };

  componentDidMount() {
      API.trackPage(Constants.pages.HOME_PAGE);
  }

  render = () => {
      const { state: { username, password } } = this;
      const { id: whitelabelId } = this.props.whitelabel || {};
      return (
          <div className="app-container container">
              <AccountProvider>
                  {({ isLoading, error }, { login, clearError }) => (
                      <div className="text-center">
                          <div className="col-md-4 offset-md-4">
                              <div className="card card--dark pb-5">
                                  <div className="card-body">
                                      {!this.props.whitelabel ? null : whitelabelId ? (
                                          <img
                                            src={`${Project.api}public/whitelabel/${whitelabelId}/logo`} alt="logo" className="img-fluid mb-4"
                                          />
                                      ) : (
                                          <img
                                            className="mb-4" src="/images/JPL_logo.svg" alt="logo"
                                          />
                                      )}
                                      <form className="form-signin" onSubmit={(e) => { e.preventDefault(); clearError(); login({ username, password }); }}>
                                          <h1 className="h3">Log In</h1>
                                          <label>Email address</label>
                                          <input
                                            onChange={e => this.setState({ username: Utils.safeParseEventValue(e) })} value={this.state.username} type="text"
                                            className="form-control"
                                            placeholder="Username"
                                          />
                                          <label>Password</label>
                                          <input
                                            onChange={e => this.setState({ password: Utils.safeParseEventValue(e) })} value={this.state.password} type="password"
                                            className="form-control"
                                            placeholder="Password"
                                          />
                                          <button
                                            disabled={isLoading} type="submit" className="btn-lg btn--primary btn-block mt-4"
                                          >
                                              {isLoading ? 'Loading' : 'Sign in'}
                                          </button>
                                          <p className="text-white">{typeof error === 'string' ? error : ''}</p>
                                      </form>
                                  </div>
                              </div>
                          </div>
                      </div>
                  )}
              </AccountProvider>
          </div>
      );
  }
}));
