import ReportPermissions from '../stores/report-permissions-store';
import GroupsStore from '../stores/user-groups-store';

export default (WrappedComponent) => {
    class HOC extends React.Component {
        static displayName = 'withReportPermissions';

        constructor(props) {
            super(props);
            ES6Component(this);
            this.listenTo(ReportPermissions, 'change', () => {
                this.setState({
                    permissions: ReportPermissions.isLoading ? this.state.permissions : [].concat(ReportPermissions.getReportPermissions(this.props.id) || []),
                    permissionsLoading: ReportPermissions.isLoading,
                    permissionsSaving: ReportPermissions.isSaving,
                });
            });
            this.listenTo(GroupsStore, 'loaded', () => {
                this.setState({
                    permissions: ReportPermissions.isLoading ? this.state.permissions : [].concat(ReportPermissions.getReportPermissions(this.props.id) || []),
                    permissionsLoading: ReportPermissions.isLoading,
                    permissionsSaving: ReportPermissions.isSaving,
                });
            });
            this.state = {
                permissions: [].concat(ReportPermissions.getReportPermissions(this.props.id) || []),
                permissionsLoading: ReportPermissions.isLoading,
                permissionsSaving: ReportPermissions.isSaving,
            };
        }

        componentDidMount() {
            if (this.wrappedComponent.onSave) {
                this.listenTo(ReportPermissions, 'saved', () => this.wrappedComponent.onSave());
            }
            if (this.wrappedComponent.onDelete) {
                this.listenTo(ReportPermissions, 'deleted', this.wrappedComponent.onDelete);
            }
        }

        setReportPermissions =(permissions) => {
            this.setState({ permissions: [].concat(permissions) });
        }

        saveReportPermissions = () => {
            AppActions.setReportPermissions(this.props.id, this.state.permissions);
        }

        render() {
            const { permissions, permissionsLoading, permissionsSaving } = this.state;
            const { setReportPermissions, saveReportPermissions } = this;
            return (
                <WrappedComponent
                  ref={c => this.wrappedComponent = c}
                  permissions={permissions}
                  permissionsLoading={permissionsLoading}
                  saveReportPermissions={saveReportPermissions}
                  permissionsSaving={permissionsSaving}
                  setReportPermissions={setReportPermissions}
                  {...this.props}
                />
            );
        }
    }

    return HOC;
};