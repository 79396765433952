import React from 'react';
import {
    Plugin,
    Template,
    TemplatePlaceholder,
} from '@devexpress/dx-react-core';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Functions from '@material-ui/icons/Functions';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import FieldStore from '../../common/stores/field-store';

const pluginDependencies = [
    { name: 'Toolbar' },
];

const ToggleButton = ({
    onToggle, title,
    buttonRef, active,
    ...restProps
}) => (
    <Tooltip
      title={title}
      placement="bottom"
      enterDelay={300}
    >
        <IconButton
          onClick={onToggle}
          buttonRef={buttonRef}
          {...restProps}
        >
            <Functions />
        </IconButton>
    </Tooltip>
);

const Overlay = ({
    visible, onHide, children, target, ...restProps
}) => (
    <Popover
      open={visible}
      anchorEl={target}
      onClose={onHide}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      {...restProps}
    >
        {children}
    </Popover>
);

const Container = ({ children, ...restProps }) => (
    <List
      dense
      {...restProps}
    >
        {children}
    </List>
);

export default class SummaryChooser extends React.PureComponent {
  state = { visible: false, addVisible: false }

  setButtonRef = (button) => {
      this.button = button;
  }

  handleToggle = () => {
      const { visible } = this.state;
      this.setState({ visible: !visible });
  }

  handleHide = () => {
      this.setState({ visible: false });
  }

  handleTypeChange = (index, type) => {
      const summaries = _.cloneDeep(this.props.summaries);
      summaries[index].type = type;
      this.props.onChange(summaries);
  }

  setAddButtonRef = (button) => {
      this.addButton = button;
  }

  handleShowAdd = () => {
      this.setState({ addVisible: true });
  }

  handleHideAdd = () => {
      this.setState({ addVisible: false });
  }

  onAdd = (e) => {
      const summaries = _.cloneDeep(this.props.summaries);
      summaries.push({ columnName: Utils.safeParseEventValue(e), type: 'sum' });
      this.props.onChange(summaries);
      this.setState({ addVisible: false });
  }

  onRemove = (index) => {
      const summaries = _.cloneDeep(this.props.summaries);
      summaries.splice(index, 1);
      this.props.onChange(summaries);
  }

  render() {
      const { summaries, selection } = this.props;
      const { visible, addVisible } = this.state;
      return (
          <Plugin
            name="SummaryChooser"
            dependencies={pluginDependencies}
          >
              <Template name="toolbarContent">
                  <TemplatePlaceholder />
                  <React.Fragment>
                      <ToggleButton
                        onToggle={this.handleToggle}
                        active={visible}
                        buttonRef={this.setButtonRef}
                        title="Show Summary Chooser"
                      />
                      <Overlay
                        visible={visible}
                        target={this.button}
                        onHide={this.handleHide}
                      >
                          <Container style={{ minWidth: 300 }}>
                              {summaries.map((item, index) => {
                                  const { columnName, type, alignByColumn } = item;
                                  if (alignByColumn === false) return null; // Generic count of groups summary
                                  const { label, fieldType } = _.find(FieldStore.getFields(), { fieldKey: columnName });
                                  if (fieldType !== 'NUMBER') return null;
                                  return (
                                      <ListItem key={`${columnName}${index}`}>
                                          <ListItemText>{label}</ListItemText>
                                          <Select
                                            value={type}
                                            onChange={e => this.handleTypeChange(index, Utils.safeParseEventValue(e))}
                                            style={{ width: 100 }}
                                          >
                                              <MenuItem value="sum">Sum</MenuItem>
                                              <MenuItem value="min">Min</MenuItem>
                                              <MenuItem value="max">Max</MenuItem>
                                              <MenuItem value="avg">Avg</MenuItem>
                                              <MenuItem value="count">Count</MenuItem>
                                          </Select>
                                          <IconButton
                                            onClick={() => this.onRemove(index)}
                                          >
                                              <RemoveCircleOutline/>
                                          </IconButton>
                                      </ListItem>
                                  );
                              })}
                          </Container>
                          <Row style={{ justifyContent: 'center' }}>
                              <Button
                                onClick={this.handleShowAdd}
                                buttonRef={this.setAddButtonRef}
                              >
                                  Add
                              </Button>
                          </Row>
                          <Overlay
                            visible={addVisible}
                            target={this.addButton}
                            onHide={this.handleHideAdd}
                          >
                              <ListItem>
                                  <FormControl style={{ minWidth: 120 }}>
                                      <InputLabel shrink htmlFor="field">
                                        Select Column
                                      </InputLabel>
                                      <Select
                                        inputProps={{
                                            id: 'field',
                                        }}
                                        onChange={this.onAdd}
                                        value=""
                                      >
                                          {_.map(_.uniq(selection, _.map(summaries, 'columnName')), (fieldKey) => {
                                              const { label, fieldType } = _.find(FieldStore.getFields(), { fieldKey });
                                              if (fieldType !== 'NUMBER') return null;
                                              return <MenuItem key={fieldKey} value={fieldKey}>{label}</MenuItem>;
                                          })}
                                      </Select>
                                  </FormControl>
                              </ListItem>
                          </Overlay>
                      </Overlay>
                  </React.Fragment>
              </Template>
          </Plugin>
      );
  }
}
