import UsersStore from '../stores/users-store';

export default (WrappedComponent) => {
    class HOC extends React.Component {
        static displayName = 'withUsers';

        constructor(props) {
            super(props);
            ES6Component(this);
            this.listenTo(UsersStore, 'change', () => {
                this.setState({
                    users: UsersStore.getUsers(),
                    usersLoading: UsersStore.isLoading,
                });
            });
            this.state = {
                users: UsersStore.getUsers(),
                usersLoading: UsersStore.isLoading,
            };
        }

        render() {
            const { users, usersLoading } = this.state;
            return (
                <WrappedComponent
                  ref="wrappedComponent"
                  users={users}
                  usersLoading={usersLoading}
                  {...this.props}
                />
            );
        }
    }

    return HOC;
};