const TreeIcon = ({ type }) => {
    switch (type) {
        case 'FOLDER':
            return (
                <i className="fas fa-folder" />
            );
        default:
            return (
                <i className="fas fa-file" />
            );
    }
};

TreeIcon.propTypes = {
    type: propTypes.string,
};

module.exports = TreeIcon;