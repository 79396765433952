import BaseStore from './base/_store';
import data from './base/_data';

const store = Object.assign({}, BaseStore, {
    id: 'user-groups',
    getUserGroups() {
        return store.model;
    },

    getUserGroup(id) {
        return store.model && _.find(store.model, { id: parseInt(id) });
    },

});

const controller = {
    getUserGroups: () => {
        store.loading();
        data.get(`${Project.api}user-groups`).then((res) => {
            store.model = res.data;
            AsyncStorage.setItem('user-groups', JSON.stringify(store.model));
            store.loaded();
        }).catch(res => API.ajaxHandler(store, res));
        // setTimeout(() => {
        //     store.model = require('../../tests/pact/responses/admin/user-groups.response').data;
        //     AsyncStorage.setItem('user-groups', JSON.stringify(store.model));
        //     store.loaded();
        // });
    },

    saveUserGroup: (details) => {
        store.saving();
        data.put(`${Project.api}user-groups/${details.id}`, details).then((res) => {
            const index = _.findIndex(store.model, { id: details.id });
            if (index !== -1) {
                store.model[index] = res;
            }
            store.saved();
        }).catch(res => API.ajaxHandler(store, res));
        // setTimeout(() => {
        //     store.saved();
        // });
    },

    createUserGroup: (details) => {
        store.saving();
        data.post(`${Project.api}user-groups`, details).then((res) => {
            store.model = store.model || [];
            store.model.push(res);
            store.savedItem = store.model[store.model.length - 1];
            store.saved();
        }).catch(res => API.ajaxHandler(store, res));
        // setTimeout(() => {
        //     store.model = require('../../tests/pact/responses/admin/user-groups.response').data;
        //     store.savedItem = store.model[store.model.length - 1];
        //     store.saved();
        // });
    },

    deleteUserGroup: (id) => {
        store.saving();
        data.delete(`${Project.api}user-groups/${id}`).then(() => {
            const index = _.findIndex(store.model, { id });
            if (index !== -1) {
                store.model.splice(index, 1);
            }
            store.saved();
            store.trigger('deleted');
        }).catch(res => API.ajaxHandler(store, res));
        // setTimeout(() => {
        //     const index = _.findIndex(store.model, { id });
        //     if (index !== -1) {
        //         store.model.splice(index, 1);
        //     }
        //     store.saved();
        //     store.trigger('deleted');
        // });
    },
};

store.dispatcherIndex = Dispatcher.register(store, (payload) => {
    const action = payload.action; // this is our action from handleViewAction

    switch (action.actionType) {
        case Actions.GET_USER_GROUPS:
            controller.getUserGroups();
            break;
        case Actions.UPDATE_USER_GROUP:
            controller.saveUserGroup(action.data);
            break;
        case Actions.CREATE_USER_GROUP:
            controller.createUserGroup(action.data);
            break;
        case Actions.DELETE_USER_GROUP:
            controller.deleteUserGroup(action.id);
            break;
        default:
    }
});

AsyncStorage.getItem('user-groups', (err, res) => {
    if (res) {
        store.model = JSON.parse(res);
    }
});
controller.store = store;
module.exports = controller.store;
