/**
 * Created by niallquinn on 18/12/2018.
 */

import React, { Component } from 'react';

export default hot(module)(class ManageUsersView extends Component {
    static displayName = 'ManageUsersPage';

    render() {
        $(() => {
            $('[data-toggle="tooltip"]').tooltip();
        });
        return (
            <div />
        );
    }
});