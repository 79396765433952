import React, { Component } from 'react';

import withFolderFilters from '../../common/providers/withFolderFilters';
import ManageFilters from './ManageFilters';
import FieldStore from '../../common/stores/field-store';

const ManageFolderFilters = class extends Component {
  static displayName = 'ManageFolderFilters';

  constructor(props) {
      super(props);
      AppActions.getFolderFilters(props.id);
  }

  componentWillUpdate(nextProps) {
      if (nextProps.id !== this.props.id) {
          AppActions.getFolderFilters(nextProps.id);
      }
  }

  onSave = (group) => {
      this.props.onSave && this.props.onSave();
  }

  render() {
      const { filters, filtersLoading, filtersSaving, setFolderFilters, saveFolderFilters } = this.props;
      return (
          <div>
              {filtersLoading ? (
                  <Loader/>
              ) : (
                  <ManageFilters filters={filters} onChange={setFolderFilters} />
              )}
              <div className="modal-footer">
                  <button type="button" className="btn--secondary-outline" data-dismiss="modal">CANCEL</button>
                  <button
                    onClick={saveFolderFilters} disabled={filtersSaving || !Utils.isFiltersValid(filters, FieldStore.getFields())} type="button"
                    className="btn--primary-outline"
                  >
                      {filtersSaving ? 'Saving' : 'Save'}
                  </button>
              </div>
          </div>
      );
  }
};

ManageFolderFilters.propTypes = {};

module.exports = withFolderFilters(ManageFolderFilters);
