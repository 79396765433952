import AccountStore from '../stores/account-store';

export default (WrappedComponent) => {
    class HOC extends React.Component {
        static displayName = 'withFolders';

        constructor(props) {
            super(props);
            ES6Component(this);
            this.listenTo(AccountStore, 'change', () => {
                this.setState({
                    folders: AccountStore.getAllFolders(),
                });
            });
            this.state = {
                folders: AccountStore.getAllFolders(),

            };
        }

        render() {
            const { folders } = this.state;
            return (
                <WrappedComponent
                  ref="wrappedComponent"
                  folders={folders}
                  {...this.props}
                />
            );
        }
    }

    return HOC;
};
