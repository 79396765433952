import React, { Component } from 'react';
import AccountStore from '../common/stores/account-store';

class App extends Component {
    static contextTypes = {
        router: propTypes.object.isRequired,
    };

    static propTypes = {
        children: propTypes.node,
    };

    static displayName = 'HomePage';

    state = {};

    toggleCollapsedSidebar= () => {
        this.setState({ navCollapsed: !this.state.navCollapsed });
    }


    onLogin = () => {
        const { context: { router: { history, route: { match: { params: { redirect } } } } } } = this;
        history.replace(redirect || Constants.loginRedirect);
    }

    onLogout = () => {
        const { context: { router: { history } } } = this;
        history.replace(Constants.logoutRedirect);
    };

    componentDidMount() {
        const { context: { router: { history: { location: { pathname } } } } } = this;
        const user = AccountStore.getUser();
        if (user && ((pathname.indexOf('/admin') === 0 && !user.isAdmin) || (pathname === '/'))) {
            this.context.router.history.replace('/dashboard');
        }
        AppActions.getWhitelabel();
    }

    getBreadcrumb = () => {
        const pathname = this.context.router.route.location.pathname;
        if (pathname === '/admin/groups') {
            return (
                <div aria-label="breadcrumb" className="d-inline-block mr-auto">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item" aria-current="page"><Link to="/dashboard">Home</Link></li>
                        <li className="breadcrumb-item active"><Link to="/admin/groups">Manage Groups</Link></li>
                    </ol>
                </div>
            );
        } if (pathname === '/admin/users') {
            return (
                <div aria-label="breadcrumb" className="d-inline-block mr-auto">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item" aria-current="page"><Link to="/dashboard">Home</Link></li>
                        <li className="breadcrumb-item active"><Link to="/admin/users">Manage Users</Link></li>
                    </ol>
                </div>
            );
        } if (pathname.match(/admin\/groups\/.+/)) {
            const parts = pathname.split('/');
            const id = parts[parts.length - 1];
            return (
                <div aria-label="breadcrumb" className="d-inline-block mr-auto">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item" aria-current="page"><Link to="/dashboard">Home</Link></li>
                        <li className="breadcrumb-item active"><Link to="/admin/groups">Manage Groups</Link></li>
                        {id ? (
                            <li className="breadcrumb-item active"><Link to={`/admin/groups/${id}`}>{id}</Link></li>

                        ) : (
                            <li className="breadcrumb-item active"><Link to="/admin/groups/create">New</Link></li>
                        )}
                    </ol>
                </div>
            );
        } if (pathname.match(/admin\/users\/.+/)) {
            const parts = pathname.split('/');
            const id = parts[parts.length - 1];
            return (
                <div aria-label="breadcrumb" className="d-inline-block mr-auto">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item" aria-current="page"><Link to="/dashboard">Home</Link></li>
                        <li className="breadcrumb-item active"><Link to="/admin/users">Manage Users</Link></li>
                        {id ? (
                            <li className="breadcrumb-item active"><Link to={`/admin/users/${id}`}>{id}</Link></li>
                        ) : (
                            <li className="breadcrumb-item active"><Link to="/admin/users/create">New</Link></li>
                        )}
                    </ol>
                </div>
            );
        }
        return <Breadcrumb/>;
    }

    render() {
        const {
            state: { navCollapsed },
        } = this;


        return (
            <AccountProvider onLogout={this.onLogout} onLogin={this.onLogin}>
                {({ user }, { logout }) => (
                    <div className={`${navCollapsed ? 'navbar--open' : ''}`}>
                        <nav className="navbar navbar-fixed-top navbar-light">
                            {user && (
                                <React.Fragment>
                                    <div className="sidebar-collapse-button">
                                        <button
                                          className="btn btn--transparent pt-0 pb-0"
                                          onClick={this.toggleCollapsedSidebar}
                                        >
                                            <i className="fas fa-bars"/>
                                        </button>
                                    </div>
                                    {this.getBreadcrumb()}
                                </React.Fragment>
                            )}

                            {/* <Breadcrumb/> */}

                            <ul className="nav justify-content-start" />
                            {user && (
                                <ul className="nav justify-content-end align-items-center">
                                    <li className="nav-item dropdown">
                                        <a
                                          className="nav-link dropdown-toggle" id="dropdown01" data-toggle="dropdown"
                                          aria-haspopup="true" aria-expanded="false"
                                        >
                                            {`${user.firstName} ${user.lastName}`}
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="dropdown01">
                                            <button
                                              onClick={logout} type="button" className="dropdown-item"
                                              href="#"
                                            >
                                                Logout
                                            </button>
                                        </div>
                                    </li>
                                </ul>
                            )}
                        </nav>
                        <div>
                            {this.props.children}
                        </div>
                    </div>
                )}
            </AccountProvider>
        );
    }
}

App.propTypes = {
};

export default hot(module)(App);
