import React, { Component } from 'react';

import {
    // DataTypeProvider,
    PagingState,
    IntegratedPaging,
    SortingState,
    IntegratedSorting,
    FilteringState,
    IntegratedFiltering,
    // SelectionState,
    // IntegratedSelection,
} from '@devexpress/dx-react-grid';


import {
    Grid,
    DragDropProvider,
    PagingPanel,
    Table,
    TableFilterRow,
    TableHeaderRow,
    TableColumnReordering,
} from '@devexpress/dx-react-grid-material-ui';


const UsersTable = class extends Component {
    static displayName = 'UsersTable';

    static contextTypes = {
        router: propTypes.object.isRequired,
    };

    state = {
        columnOrder: ['id', 'firstName', 'lastName', 'username'],
        selectedItems: {},
    };

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (nextProps.id !== this.props.id) {
            this.state.filters = [];
        }
    }

    changeSelection = selection => this.setState({ selection });

    toggle = (id) => {
        const { state: { selectedItems } } = this;
        if (selectedItems[id]) {
            delete selectedItems[id];
        } else {
            selectedItems[id] = true;
        }
        this.setState({
            selectedItems,
        });
    };

    changeColumnOrder = (newOrder) => {
        this.setState({ columnOrder: newOrder });
    }

    navigate = (id, type) => {
        const { context: { router: { history: { push } } } } = this;
        push(type === 'FOLDER' ? `/admin/users/${id}` : `/admin/users/${id}`);
    };

    render() {
        const { state: { columnOrder, selection }, props: { usersLoading, users: children } } = this;

        return children && children.length ? (

            <Grid
              rows={children}
              columns={[
                  { name: 'id', title: 'ID' },
                  { name: 'firstName', title: 'First Name' },
                  { name: 'lastName', title: 'Last Name' },
                  { name: 'username', title: 'Username' },
              ]}
            >
                {/* Filtering */}
                <FilteringState />
                <IntegratedFiltering />

                {/* Sorting */}
                <SortingState
                  defaultSorting={[{ columnName: 'type', direction: 'asc' }]}
                />
                <IntegratedSorting />

                <DragDropProvider />
                <PagingState
                  defaultCurrentPage={0}
                  defaultPageSize={10}
                />
                <IntegratedPaging />

                <Table
                  rowComponent={({ row, ...props }) => (
                      <Table.Row
                        {...props}
                        className="table-row"
                            // eslint-disable-next-line no-alert
                        onClick={() => this.navigate(row.id)}
                        style={{
                            cursor: 'pointer',
                        }}
                      />
                  )}
                  columnExtensions={[
                      { columnName: 'id', width: 100 },
                  ]}
                />

                <TableColumnReordering
                  order={columnOrder}
                  onOrderChange={this.changeColumnOrder}
                />

                {/* Paging */}
                <PagingPanel pageSizes={[10, 15, 20, 1000]} />

                {/* The table header */}
                <TableHeaderRow showSortingControls />
                <TableFilterRow
                  cellComponent={props => (
                      <TableFilterRow.Cell {...props} className="filter"/>
                  )}
                  showSortingControls
                />
            </Grid>
        ) : (
            <div className="container-fluid text-center">
                {usersLoading ? <Loader/> : (
                    <div className="row pb-3">
                        <div className="col-sm-12">
                            <img alt="Empty Folder" className="mb-5" src="/images/empty_folder_Icon_.svg" />
                            <h1 className="h1--secondary">No users</h1>
                        </div>
                    </div>
                )}
            </div>
        );
    }
};

UsersTable.propTypes = {
    users: propTypes.array,
};

module.exports = UsersTable;
