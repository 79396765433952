import BaseStore from './base/_store';
import data from './base/_data';

const store = Object.assign({}, BaseStore, {
    id: 'folder-permissions',
    getFolderPermissions(id) {
        return store.model && store.folderId === id && store.model;
    },

});

const controller = {
    getFolderPermissions: (id) => {
        store.loading();
        data.get(`${Project.api}folders/${id}/user-groups`).then((res) => {
            store.model = res.data;
            store.folderId = id;
            store.loaded();
        }).catch(res => API.ajaxHandler(store, res));
        // setTimeout(() => {
        //     store.model = require('../../tests/pact/responses/admin/folder-permissions.response').data;
        //     store.folderId = id;
        //     store.loaded();
        // });
    },
    setFolderPermissions: (id, groups) => {
        store.saving();
        data.put(`${Project.api}folders/${id}/user-groups`, { data: groups }).then((res) => {
            store.model = res.data;
            store.folderId = id;
            store.saved();
        }).catch(res => API.ajaxHandler(store, res));
        // setTimeout(() => {
        //     store.model = require('../../tests/pact/responses/admin/folder-permissions.response').data;
        //     store.folderId = id;
        //     store.saved();
        // });
    },

};

store.dispatcherIndex = Dispatcher.register(store, (payload) => {
    const action = payload.action; // this is our action from handleViewAction

    switch (action.actionType) {
        case Actions.GET_FOLDER_PERMISSIONS:
            controller.getFolderPermissions(action.id);
            break;
        case Actions.SET_FOLDER_PERMISSIONS:
            controller.setFolderPermissions(action.id, action.groups);
            break;
        default:
    }
});


controller.store = store;
module.exports = controller.store;
