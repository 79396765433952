import BaseStore from './base/_store';
import data from './base/_data';

const store = Object.assign({}, BaseStore, {
    id: 'account',
    getUser() {
        return store.model;
    },


    getCanSync() {
        return store.model && store.model.canSync;
    },


    getAllFolders() {
        return store.allFolders || [];
    },

    getNavigation() {
        return store.navigation;
    },
    getExpandedItems() {
        return store.expanded || {};
    },

    getBreadcrumb() {
        return store.breadcrumb || [];
    },

});

const controller = {
    register: () => {
        store.saving();
        controller.onLogin({});
        store.saved();
    },
    setToken: (model) => {
        data.setToken(model.token);
        store.model = model;
    },

    login: (details) => {
        store.loading();
        data.post(`${Project.api}auth/login`, details).then((details) => {
            controller.onLogin(details);
            store.loaded();
        }).catch(res => API.ajaxHandler(store, res));
    },

    getFolders(id) {
        store.navId = id;
        return data.get(`${Project.api}folders`).then((res) => {
            controller.initialiseNavigation(res.data);
            store.trigger('navigation');
        }).catch(res => API.ajaxHandler(store, res));
        // return new Promise((resolve) => {
        //     controller.initialiseNavigation(require('../../tests/pact/responses/folders.response').data);
        //     resolve();
        // });
    },

    logout: () => {
        store.model = null;
        store.navigation = null;
        store.mappings = null;
        store.breadcrumb = null;
        store.navId = null;
        store.expanded = null;
        AsyncStorage.clear();
        store.trigger('logout');
    },

    onLogin(res) {
        if (res) {
            store.model = res;
            controller.setToken(res);
            AsyncStorage.setItem('t', JSON.stringify(res));
        }
    },

    createFolder(details) {
        data.post(`${Project.api}folders`, details)
            .then((res) => {
                // Update the navigation mappings and tree
                let node = store.navigation;
                let mapping = [];
                _.each(store.breadcrumb, (crumb, index) => {
                    const nodeIndex = _.findIndex(index === 0 ? node : node.children, { id: crumb.id });
                    node = index === 0 ? node[nodeIndex] : node.children[nodeIndex];
                    mapping = mapping.concat(index === 0 ? [nodeIndex] : ['children', nodeIndex]);
                });
                store.mappings[res.id] = mapping.concat(['children', node.children.length]);
                node.children.push(res);
                node.items.push(Object.assign({}, res, { type: 'FOLDER', items: [] }));

                store.saved();
                store.trigger('folder-created', res.id);
            })
            .catch((e) => {
                toast('Could not create folder');
                API.ajaxHandler(store, e);
            });
    },

    createReport(report) {
        store.saving();
        data.post(`${Project.api}reports`, report)
            .then((res) => {
                // Update the navigation mappings and tree
                let node = store.navigation;
                let mapping = [];
                _.each(store.breadcrumb, (crumb, index) => {
                    const nodeIndex = _.findIndex(index === 0 ? node : node.children, { id: crumb.id });
                    node = index === 0 ? node[nodeIndex] : node.children[nodeIndex];
                    mapping = mapping.concat(index === 0 ? [nodeIndex] : ['children', nodeIndex]);
                });
                store.mappings[res.id] = mapping.concat(['reports', node.reports.length]);
                node.items.push(Object.assign({}, res, { type: 'FILE' }));
                node.reports.push(res);

                store.saved();
                store.trigger('report-created', res.id);
            })
            .catch((e) => {
                toast('Could not create report');
                API.ajaxHandler(store, e);
            });
    },

    deleteReport(id) {
        store.loading();
        data.delete(`${Project.api}reports/${id}`, id)
            .then((res) => {
                // Remove report from navigation tree
                const folderPath = store.mappings[id].slice(0, store.mappings[id].length - 2);
                const { items, reports } = _.get(store.navigation, folderPath);
                items.splice(_.findIndex(items, { id }), 1);
                reports.splice(_.findIndex(reports, { id }), 1);

                // Correct mapping indexes for all remaining reports inside the folder
                _.each(reports, (report, i) => {
                    store.mappings[report.id][store.mappings[report.id].length - 1] = i;
                });

                // Delete the report mapping
                delete store.mappings[id];

                store.trigger('deleted', id);
            })
            .catch((e) => {
                toast('Could not delete report');
                API.ajaxHandler(store, e);
            });
    },

    deleteFolder(id) {
        store.loading();
        data.delete(`${Project.api}folders/${id}`, id)
            .then((res) => {
                const parentId = store.breadcrumb && store.breadcrumb.length >= 2 ? store.breadcrumb[store.breadcrumb.length - 2].id : null;
                controller.getFolders().then(() => store.trigger('deleted', parentId));
            })
            .catch((e) => {
                toast('Could not delete folder');
                API.ajaxHandler(store, e);
            });
    },

    editFolder(id, details) {
        data.put(`${Project.api}folders/${id}`, details)
            .then((res) => {
                // Update the navigation tree
                const node = _.get(store.navigation, store.mappings[id]);
                node.name = details.name;
                if (store.mappings[id].length > 1) {
                    // Its a subfolder, update items of parent folder
                    const items = _.get(store.navigation, store.mappings[id].slice(0, store.mappings[id].length - 2)).items;
                    items[_.findIndex(items, { id: res.id })].name = details.name;
                }
                // Update the breadcrumb
                _.last(store.breadcrumb).name = details.name;

                store.saved();
                store.trigger('folder-updated', res.id);
                store.trigger('navigation');
            })
            .catch((e) => {
                toast('Could not update folder');
                API.ajaxHandler(store, e);
            });
    },

    editReport(id, details) {
        data.put(`${Project.api}reports/${id}`, details)
            .then((res) => {
                // Update the navigation tree
                const node = _.get(store.navigation, store.mappings[id]);
                node.name = details.name;
                const items = _.get(store.navigation, store.mappings[id].slice(0, store.mappings[id].length - 2)).items;
                items[_.findIndex(items, { id: res.id })].name = details.name;

                // Update the breadcrumb
                _.last(store.breadcrumb).name = details.name;

                store.saved();
                store.trigger('report-updated', res.id);
            })
            .catch((e) => {
                toast('Could not update report');
                API.ajaxHandler(store, e);
            });
    },

    generateNode(node, index, tree = [], breadcrumb = '') {
        store.mappings[node.id] = tree.length ? tree.concat(['children', index]) : tree.concat([index]);
        breadcrumb += `${breadcrumb ? ' > ' : ''}${node.name}`;
        store.allFolders.push({ id: node.id, name: breadcrumb });
        if (node.reports) {
            _.each(node.reports, (report, i) => {
                store.mappings[report.id] = store.mappings[node.id].concat(['reports', i]);
            });
        }
        return {
            ...node,
            children: node.children && _.sortBy(node.children, item => item.name.toLowerCase()).map((item, childIndex) => controller.generateNode(item, childIndex, store.mappings[node.id], breadcrumb)),
        };
    },

    initialiseNavigation(folders) {
        store.mappings = {};
        store.allFolders = [];
        store.navigation = folders.map((node, i) => controller.generateNode(node, i, []));
        AsyncStorage.setItem('folders', JSON.stringify(folders));
        controller.setNavigation(store.navId);
    },

    setNavigation(id) {
        const newExpanded = {};
        if (typeof id === 'number') {
            const mapping = store.mappings[id];
            if (!mapping) {
                console.error('No folder mapping for item with id', id);
                return;
            }
            const breadcrumb = _.filter(mapping.map((x, mappingIndex) => {
                if (x === 'children' || x === 'reports') {
                    return;
                }
                const items = _.take(mapping, mappingIndex + 1);
                return _.get(store.navigation, items.join('.'));
            }), e => e);
            _.each(breadcrumb, ({ id }, i) => {
                newExpanded[id] = true;
                breadcrumb[i].items = (breadcrumb[i].children || []).map(item => ({ ...item, type: 'FOLDER' }))
                    .concat((breadcrumb[i].reports || []).map(item => ({ ...item, type: 'FILE' })));
            });
            store.breadcrumb = breadcrumb;
        } else {
            store.breadcrumb = [];
        }
        store.expanded = newExpanded;
        store.trigger('navigation');
    },

    setUser(user) {
        AppActions.getFields();
        controller.onLogin(user);
        store.loaded();
    },

};

store.dispatcherIndex = Dispatcher.register(store, (payload) => {
    const action = payload.action; // this is our action from handleViewAction

    switch (action.actionType) {
        case Actions.SET_USER:
            controller.setUser(action.user);
            break;
        case Actions.LOGOUT:
            controller.logout();
            break;
        case Actions.REGISTER:
            controller.register(action.details);
            break;
        case Actions.LOGIN:
            controller.login(action.details);
            break;
        case Actions.SET_TOKEN:
            controller.setToken(action.token);
            break;
        case Actions.GET_FOLDERS:
            controller.getFolders(action.defaultFolder);
            break;
        case Actions.SET_NAVIGATION:
            controller.setNavigation(action.id);
            break;
        case Actions.CREATE_FOLDER:
            controller.createFolder(action.data);
            break;
        case Actions.CREATE_REPORT:
            controller.createReport(action.data);
            break;
        case Actions.DELETE_REPORT:
            controller.deleteReport(action.id);
            break;
        case Actions.DELETE_FOLDER:
            controller.deleteFolder(action.id);
            break;
        case Actions.EDIT_FOLDER:
            controller.editFolder(action.id, action.details);
            break;
        case Actions.EDIT_REPORT:
            controller.editReport(action.id, action.details);
            break;
        default:
    }
});
//
// AsyncStorage.getItem('folders', (err, res) => {
//     if (res) {
//         store.mappings = {};
//         store.navigation = JSON.parse(res).map((node, i) => controller.generateNode(node, i, node.children));
//     }
// });
controller.store = store;
module.exports = controller.store;
global.AccountStore = store;
