import base from './base/_action-constants';

const Actions = Object.assign({}, base, {
    CREATE_FOLDER: 'CREATE_FOLDER',
    CREATE_REPORT: 'CREATE_REPORT',
    DELETE_REPORT: 'DELETE_REPORT',
    DELETE_FOLDER: 'DELETE_FOLDER',
    CREATE_USER: 'CREATE_USER',
    CREATE_USER_GROUP: 'CREATE_USER_GROUP',
    DELETE_USER: 'DELETE_USER',
    DELETE_USER_GROUP: 'DELETE_USER_GROUP',
    EDIT_FOLDER: 'EDIT_FOLDER',
    EDIT_REPORT: 'EDIT_REPORT',
    GET_CASE: 'GET_CASE',
    GET_FIELDS: 'GET_FIELDS',
    GET_FOLDER_FILTERS: 'GET_FOLDER_FILTERS',
    GET_FOLDER_PERMISSIONS: 'GET_FOLDER_PERMISSIONS',
    GET_FOLDERS: 'GET_FOLDERS',
    GET_REPORT: 'GET_REPORT',
    GET_REPORT_CONTENTS: 'GET_REPORT_CONTENTS', // Refresh contents of a report, leaving config as-is.
    GET_REPORT_FILTERS: 'GET_REPORT_FILTERS',
    GET_REPORT_PERMISSIONS: 'GET_REPORT_PERMISSIONS',
    GET_USER: 'GET_USER',
    GET_USER_GROUP: 'GET_USER_GROUP',
    GET_USER_GROUPS: 'GET_USER_GROUPS',
    GET_USERS: 'GET_USERS',
    GET_WHITELABEL: 'GET_WHITELABEL',
    SET_FOLDER_FILTERS: 'SET_FOLDER_FILTERS',
    SET_FOLDER_PERMISSIONS: 'SET_FOLDER_PERMISSIONS',
    SET_NAVIGATION: 'SET_NAVIGATION',
    SET_REPORT_FILTERS: 'SET_REPORT_FILTERS',
    SET_REPORT_PERMISSIONS: 'SET_REPORT_PERMISSIONS',
    UPDATE_USER: 'UPDATE_USER',
    UPDATE_USER_GROUP: 'UPDATE_USER_GROUP',
    UPDATE_USER_PASSWORD: 'UPDATE_USER_PASSWORD',
    GET_REPORT_CONTENTS: 'GET_REPORT_CONTENTS'
});

export default Actions;
window.Actions = Actions;