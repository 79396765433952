import React, { Component } from 'react';
import withFolderNavigation from '../../common/providers/withFolderNavigation';
import AccountStore from '../../common/stores/account-store';
import withWhitelabel from '../../common/providers/withWhitelabel';
import _data from '../../common/stores/base/_data';
import { openModal } from '../project/modals';

const Sidebar = withWhitelabel(class extends Component {
    static displayName = 'Sidebar';

    static contextTypes = {
        router: propTypes.object.isRequired,
    };

    constructor(props, context) {
        super(props, context);
        this.state = {};
        if (AccountStore.getNavigation()) {
            AppActions.setNavigation(!!this.props.node && parseInt(this.props.node));
        } else {
            AppActions.getFolders(!!this.props.node && parseInt(this.props.node));
        }
    }

    componentWillUpdate({ node: newId }) {
        const { node: id } = this.props;
        if (newId !== id) {
            AppActions.setNavigation(!!newId && parseInt(newId));
        }
    }

    navigate = (id) => {
        const { context: { router: { history: { push } } } } = this;
        if (id == null) {
            push('/dashboard');
        } else {
            push(`/folders/${id}`);
        }
    };


    sync = () => {
        this.setState({ isSaving: true });
        _data.get(`${Project.api}reports/sync`)
            .then(() => {
                this.setState({ isSaving: false });
            })
            .catch(() => {
                this.setState({ isSaving: false });
                openModal({
                    className: 'folder-select-modal',
                    header: (
                        'There was an issue'
                    ),
                    body: 'Could not sync report data',
                });
            });
    }

    render() {
        const { navigate, props: { tree, expandedItems } } = this;
        const isAdmin = AccountStore.getUser().isAdmin;
        const { id: whitelabelId } = this.props.whitelabel || {};
        return (
            <nav className="pl-0 pr-0 col-sm-2 sidebar">
                <Link to="/">

                    <br/>
                    {whitelabelId ? (
                        <img
                          src={`${Project.api}public/whitelabel/${whitelabelId}/logo`} alt="logo"
                          className="sidebar__logo img-fluid mt-3"
                        />
                    ) : (
                        <img
                          src="/images/JPL_logo.svg" alt="logo"
                          className="sidebar__logo img-fluid mt-3"
                        />
                    )}
                </Link>
                <div className="sidebar__content">
                    <h5 className="sidebar__folder-section-title">Folders</h5>
                    {tree ? tree.map(item => (
                        <TreeNode
                          key={item.id}
                          expandedItems={expandedItems}
                          onClick={navigate}
                          {...item}
                        />
                    )) : <div className="text-center"><Loader /></div>}
                    {isAdmin ? (
                        <ul className="nav sidebar__account flex-column ml-0 mr-0">
                            <hr/>
                            <h5 className="sidebar__folder-section-title">Account</h5>
                            <li className="nav-item">
                                <NavLink
                                  to="/admin/users" className="nav-link"
                                  href="#"
                                >
                                    <i className="icon ion-md-person-add mr-3" />
                                    Manage Users
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/admin/groups" className="nav-link">
                                    <i className="fas fa-users mr-3" />
                                    Manage Groups
                                </NavLink>
                            </li>
                            {AccountStore.getCanSync() && (
                                <li className="nav-item text-center mt-5">
                                    <Button onClick={this.sync} className="btn btn--primary" disabled={this.state.isSaving}>Sync all</Button>
                                </li>
                            )}
                        </ul>
                    ) : null}
                </div>
            </nav>
        );
    }
});

Sidebar.propTypes = {};

module.exports = withFolderNavigation(Sidebar);
