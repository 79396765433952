import FolderPermissions from '../stores/folder-permissions-store';
import GroupsStore from '../stores/user-groups-store';

export default (WrappedComponent) => {
    class HOC extends React.Component {
        static displayName = 'withFolderPermissions';

        constructor(props) {
            super(props);
            ES6Component(this);
            this.listenTo(FolderPermissions, 'change', () => {
                this.setState({
                    permissions: FolderPermissions.isLoading ? this.state.permissions : [].concat(FolderPermissions.getFolderPermissions(this.props.id) || []),
                    permissionsLoading: FolderPermissions.isLoading,
                    permissionsSaving: FolderPermissions.isSaving,
                });
            });
            this.listenTo(GroupsStore, 'loaded', () => {
                this.setState({
                    permissions: FolderPermissions.isLoading ? this.state.permissions : [].concat(FolderPermissions.getFolderPermissions(this.props.id) || []),
                    permissionsLoading: FolderPermissions.isLoading,
                    permissionsSaving: FolderPermissions.isSaving,
                });
            });
            this.state = {
                permissions: [].concat(FolderPermissions.getFolderPermissions(this.props.id) || []),
                permissionsLoading: FolderPermissions.isLoading,
                permissionsSaving: FolderPermissions.isSaving,
            };
        }

        componentDidMount() {
            if (this.wrappedComponent.onSave) {
                this.listenTo(FolderPermissions, 'saved', () => this.wrappedComponent.onSave());
            }
            if (this.wrappedComponent.onDelete) {
                this.listenTo(FolderPermissions, 'deleted', this.wrappedComponent.onDelete);
            }
        }

        setFolderPermissions =(permissions) => {
            this.setState({ permissions: [].concat(permissions) });
        }

        saveFolderPermissions = () => {
            AppActions.setFolderPermissions(this.props.id, this.state.permissions);
        }

        render() {
            const { permissions, permissionsLoading, permissionsSaving } = this.state;
            const { setFolderPermissions, saveFolderPermissions } = this;
            return (
                <WrappedComponent
                  ref={c => this.wrappedComponent = c}
                  permissions={permissions}
                  permissionsLoading={permissionsLoading}
                  saveFolderPermissions={saveFolderPermissions}
                  permissionsSaving={permissionsSaving}
                  setFolderPermissions={setFolderPermissions}
                  {...this.props}
                />
            );
        }
    }

    return HOC;
};