import CaseStore from '../stores/case-store';

export default (WrappedComponent) => {
    class HOC extends React.Component {
        static displayName = 'withCase';

        constructor(props) {
            super(props);
            ES6Component(this);
            this.listenTo(CaseStore, 'change', () => {
                this.setState({
                    caseDetails: CaseStore.getCase(this.props.id),
                    caseLoading: CaseStore.isLoading,
                    caseSaving: CaseStore.isSaving,
                });
            });
            this.state = {
                caseDetails: CaseStore.getCase(this.props.id),
                caseLoading: CaseStore.isLoading,
                caseSaving: CaseStore.isSaving,
            };
        }

        render() {
            const { caseDetails, caseLoading, caseSaving } = this.state;
            return (
                <WrappedComponent
                  ref={c => this.wrappedComponent = c}
                  caseDetails={caseDetails}
                  caseLoading={caseLoading}
                  caseSaving={caseSaving}
                  {...this.props}
                />
            );
        }
    }

    return HOC;
};