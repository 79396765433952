import React, { Component } from 'react';

import {
    DataTypeProvider,
    PagingState,
    IntegratedPaging,
    SortingState,
    IntegratedSorting,
    FilteringState,
    IntegratedFiltering,
    SelectionState,
    IntegratedSelection,
} from '@devexpress/dx-react-grid';


import {
    Grid,
    DragDropProvider,
    PagingPanel,
    Table,
    TableFilterRow,
    TableHeaderRow,
    TableSelection,
    TableColumnReordering,
} from '@devexpress/dx-react-grid-material-ui';
import data from '../../common/stores/base/_data';

import withFolderNavigation from '../../common/providers/withFolderNavigation';

const DocumentTable = class extends Component {
    static displayName = 'DocumentTable';

    static contextTypes = {
        router: propTypes.object.isRequired,
    };

    state = {
        columnOrder: ['date', 'name', 'description', 'download'],
        selection: [],
    };

    componentWillUpdate(nextProps) {
        if (nextProps.id !== this.props.id) {
            this.state.filters = [];
            this.state.selection = [];
        }
    }

    changeSelection = selection => this.setState({ selection });

    navigate = (url) => {
        // const win = window.open(url, '_blank');
        // win.focus();
    };

    changeColumnOrder = (newOrder) => {
        this.setState({ columnOrder: newOrder });
    }

    tryDownload = (doc) => {
        fetch(`${Project.api}documents/${doc.id}/download?reportId=${this.props.reportId}&caseId=${doc.caseId}`, {
            headers: {
                AUTHORIZATION: `Bearer ${data.token}`,
            },
        })
            .then((res) => {
                if (!res.ok) throw res;
                return res.blob();
            })
            .then((res) => {
                const url = window.URL.createObjectURL(res);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', doc.filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((err) => {
                console.error(err);
                toast('Failed to download report');
            });
    }

    render() {
        const { state: { columnOrder }, props: { documents: children } } = this;
        return children && children.length ? (

            <div>
                <h6 className="pl-0 mt-3 title">ATTACHMENTS</h6>
                <Grid
                  rows={children}
                  columns={[
                      { name: 'date', title: 'Date' },
                      { name: 'description', title: 'Description' },
                      { name: 'name', title: 'Name' },
                      { name: 'download', title: 'Download' },
                  ]}
                >

                    {/* Sorting */}
                    <SortingState
                      defaultSorting={[{ columnName: 'date', direction: 'asc' }]}
                    />
                    <IntegratedSorting />


                    {/* Custom columns */}
                    <DataTypeProvider
                      for={['date']}
                      formatterComponent={({ value }) => (value ? moment(value, value.length === 10 ? 'DD/MM/YYYY' : null).format('Do MMM YYYY') : '')}
                    />
                    <DataTypeProvider
                      for={['type']}
                      formatterComponent={({ value }) => <TreeIcon type={value}/>}
                    />
                    <DataTypeProvider
                      for={['download']}
                      formatterComponent={({ value, row }) => (
                          <a onClick={(e) => { e.preventDefault(); e.nativeEvent.stopImmediatePropagation(); e.stopPropagation(); e.bubbles = false; this.tryDownload(row); }}>
                              <i
                                className="fas fa-download" data-toggle="tooltip" data-placement="top"
                                title="Download"
                              />
                          </a>
                      )}
                    />

                    {/* Filtering */}
                    <FilteringState
                      columnExtensions={[
                          { columnName: 'date', filteringEnabled: false },
                          { columnName: 'type', filteringEnabled: false },
                          { columnName: 'download', filteringEnabled: false },
                      ]}
                    />
                    <IntegratedFiltering />

                    <DragDropProvider />
                    <PagingState
                      defaultCurrentPage={0}
                      defaultPageSize={1000}
                    />
                    <IntegratedPaging />

                    <Table
                      rowComponent={({ row, ...props }) => (
                          <Table.Row
                            {...props}
                            className="table-row"
                                // eslint-disable-next-line no-alert
                            onClick={() => this.navigate(row.url)}
                            style={{
                                cursor: 'pointer',
                            }}
                          />
                      )}
                      columnExtensions={[
                          { columnName: 'date', width: 120 },
                          { columnName: 'type', width: 70 },
                          { columnName: 'download', width: 70 },
                      ]}
                    />

                    <TableColumnReordering
                      order={columnOrder}
                      onOrderChange={this.changeColumnOrder}
                    />
                    {/* Paging */}
                    <PagingPanel pageSizes={[10, 15, 20, 1000, 0]} />


                    {/* The table header */}
                    <TableFilterRow />
                    <TableHeaderRow showSortingControls />

                </Grid>
            </div>
        ) : (
            <div className="container-fluid text-center" />
        );
    }
};

DocumentTable.propTypes = {};

module.exports = DocumentTable;
