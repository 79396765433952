import FolderFilters from '../stores/folder-filters-store';
import FieldStore from '../stores/field-store';

export default (WrappedComponent) => {
    class HOC extends React.Component {
        static displayName = 'withFolderFilters';

        constructor(props) {
            super(props);
            ES6Component(this);
            this.listenTo(FolderFilters, 'change', () => {
                this.setState({
                    filters: FolderFilters.isLoading ? this.state.filters : [].concat(FolderFilters.getFolderFilters(this.props.id) || []),
                    filtersLoading: FolderFilters.isLoading,
                    filtersSaving: FolderFilters.isSaving,
                });
            });
            this.listenTo(FieldStore, 'loaded', () => {
                this.setState({
                    filters: FolderFilters.isLoading ? this.state.filters : [].concat(FolderFilters.getFolderFilters(this.props.id) || []),
                    filtersLoading: FolderFilters.isLoading,
                    filtersSaving: FolderFilters.isSaving,
                });
            });
            this.state = {
                filters: [].concat(FolderFilters.getFolderFilters(this.props.id) || []),
                filtersLoading: FolderFilters.isLoading,
                filtersSaving: FolderFilters.isSaving,
            };
        }

        componentDidMount() {
            if (this.wrappedComponent.onSave) {
                this.listenTo(FolderFilters, 'saved', () => this.wrappedComponent.onSave());
            }
            if (this.wrappedComponent.onDelete) {
                this.listenTo(FolderFilters, 'deleted', this.wrappedComponent.onDelete);
            }
        }

        setFolderFilters =(filters) => {
            this.setState({ filters: [].concat(filters) });
        }

        saveFolderFilters = () => {
            AppActions.setFolderFilters(this.props.id, this.state.filters);
        }

        render() {
            const { filters, filtersLoading, filtersSaving } = this.state;
            const { setFolderFilters, saveFolderFilters } = this;
            return (
                <WrappedComponent
                  ref={c => this.wrappedComponent = c}
                  filters={filters}
                  filtersLoading={filtersLoading}
                  saveFolderFilters={saveFolderFilters}
                  filtersSaving={filtersSaving}
                  setFolderFilters={setFolderFilters}
                  {...this.props}
                />
            );
        }
    }

    return HOC;
};