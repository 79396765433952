import React, { Component } from 'react';
import cn from 'classnames';
import withFolderNavigation from '../../common/providers/withFolderNavigation';

const Sidebar = class extends Component {
    static displayName = 'Breadcrumb';

    static contextTypes = {
        router: propTypes.object.isRequired,
    };

    static propTypes = {
        breadcrumb: propTypes.array,
    };

    getLink = (id, children) => {
        if (id == null) {
            return '/dashboard';
        }
        return children ? `/folders/${id}` : `/reports/${id}`;
    };

    render() {
        const { getLink, props: { breadcrumb } } = this;
        return breadcrumb ? (
            <div aria-label="breadcrumb" className="d-inline-block mr-auto">
                <ol className="breadcrumb">
                    <li key="home" className="breadcrumb-item" aria-current="page"><Link to="/dashboard">Home</Link></li>
                    { breadcrumb.map(({ id, type, name, children }, index) => (
                        <li
                          key={id} className={cn({
                              'breadcrumb-item': true,
                              active: index + 1 === breadcrumb.length,
                          })}
                        >
                            <Link to={getLink(id, children)}>{name}</Link>
                        </li>
                    ))}
                </ol>
            </div>
        ) : null;
    }
};

Sidebar.propTypes = {};

module.exports = withFolderNavigation(Sidebar);
