/**
 * Created by kyle-ssg on 10/02/15.
 */
export default {
    'ACTIVE': 'ACTIVE',
    'INACTIVE': 'INACTIVE',
    'CONNECTED': 'CONNECTED',
    'DISCONNECTED': 'DISCONNECTED',
    'GET_CONFIG': 'GET_CONFIG',
    'REGISTER_NOTIFICATIONS': 'REGISTER_NOTIFICATIONS',
    'LOGIN': 'LOGIN',
    'LOGOUT': 'LOGOUT',
    'REGISTER': 'REGISTER',
    'SET_TOKEN': 'SET_TOKEN',
    'SET_USER': 'SET_USER',
    'REFRESH': 'REFRESH',
};
