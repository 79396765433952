import BaseStore from './base/_store';
import data from './base/_data';

const store = Object.assign({}, BaseStore, {
    id: 'user',
    getUser(id) {
        if (store.model && store.model.id === parseInt(id)) return store.model;
        return null;
    },

});

const controller = {
    getUser: (id) => {
        store.loading();
        data.get(`${Project.api}users/${id}`).then((res) => {
            store.model = res;
            store.loaded();
        }).catch(res => API.ajaxHandler(store, res));
        // setTimeout(() => {
        //     store.model = require('../../tests/pact/responses/admin/user-details.response');
        //     store.loaded();
        // });
    },

    saveUser: (details) => {
        store.saving();
        data.put(`${Project.api}users/${store.model.id}`, details).then((res) => {
            store.model = res;
            store.saved();
        }).catch(res => API.ajaxHandler(store, res));
        // setTimeout(() => {
        //     store.model = require('../../tests/pact/responses/admin/user-details.response');
        //     store.saved();
        // });
    },

    deleteUser: (details) => {
        store.saving();
        data.delete(`${Project.api}users/${store.model.id}`, details).then((res) => {
            store.model = res;
            store.saved();
            store.trigger('deleted');
        }).catch(res => API.ajaxHandler(store, res));
        // setTimeout(() => {
        //     store.saved();
        //     store.trigger('deleted');
        // });
    },

    saveUserPassword: (details) => {
        store.saving();
        data.post(`${Project.api}users/${details.id}/password`, {
            password: details.newPassword,
            retypePassword: details.confirmNewPassword,
        }).then(() => {
            store.saved();
        }).catch(res => API.ajaxHandler(store, res));
        // setTimeout(() => {
        //     // store.model = require('../../tests/pact/responses/admin/user-details.response');
        //     store.saved();
        // });
    },

};

store.dispatcherIndex = Dispatcher.register(store, (payload) => {
    const action = payload.action; // this is our action from handleViewAction

    switch (action.actionType) {
        case Actions.GET_USER:
            controller.getUser(action.id);
            break;
        case Actions.UPDATE_USER:
            controller.saveUser(action.data);
            break;
        case Actions.UPDATE_USER_PASSWORD:
            controller.saveUserPassword(action.data);
            break;
        default:
    }
});


controller.store = store;
module.exports = controller.store;
