/**
 * Created by kylejohnson on 21/08/2016.
 */
import withUser from '../../../common/providers/withUser';
import UserGroupSelect from '../UserGroupSelect';
import UsersStore from '../../../common/stores/users-store';

const UserPage = class extends React.Component {
    displayName = 'UserPage';

    static propTypes ={
        id: propTypes.string.isRequired,
        user: propTypes.object,
        userLoading: propTypes.bool,
        deleteUser: propTypes.func.isRequired,
        setUserProperty: propTypes.func.isRequired,
        userSaving: propTypes.bool,
        saveUser: propTypes.func,
        saveUserPassword: propTypes.func.isRequired,
    };

    static contextTypes = {
        router: propTypes.object.isRequired,
    };

    componentWillMount() {
        if (this.props.id) {
            AppActions.getUser(this.props.id);
        }
        AppActions.getUserGroups();
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (nextProps.id !== this.props.id && nextProps) {
            AppActions.getUser(nextProps.id);
        }
    }

    onSave = (user) => {
        const { context: { router: { history: { replace } } } } = this;

        if (this.props.id) {
            toast('User updated');
        } else {
            toast('User created');
            replace(`/admin/users/${user.id}`);
        }
        $('.modal .close').click();
    }

    onDelete = () => {
        const { context: { router: { history: { replace } } } } = this;
        toast('User deleted');
        $('.modal .close').click();
        replace('/admin/users');
    };

    valid = () => {
        // Username must not be taken when creating a user
        if (!this.props.id && _.find(UsersStore.getUsers(), { username: this.props.user.username })) return false;

        return true;
    }

    render() {
        const { props: { user, userLoading, deleteUser, setUserProperty, userSaving, saveUser, saveUserPassword, id } } = this;
        const validNewPassword = user && user.newPassword && (user.newPassword === user.confirmNewPassword);
        const users = UsersStore.getUsers();
        return (
            <div className="mt-5">
                {/* Todo: Move to report detail page */}
                { user && (
                    <div className="tab-content" id="pills-tabContent">
                        <div
                          className="tab-pane fade show active" id="pills-user" role="tabpanel"
                          aria-labelledby="pills-user-tab"
                        >

                            <div className="card card--report">
                                <form onSubmit={saveUser}>
                                    {id ? (
                                        <ul className="nav justify-content-end">
                                            <li data-toggle="modal" data-target="#deleteUserModal" className="nav-item nav-link">
                                                <i
                                                  className="fas fa-trash" data-toggle="tooltip" data-placement="top"
                                                  title="Delete"
                                                />
                                            </li>
                                        </ul>
                                    ) : null}
                                    <h6 className="title mt-2">MANAGE USERS</h6>
                                    <div className="card-body row">
                                        <div className="col-sm-6">
                                            {this.props.id && (
                                            <div className="form-group row">
                                                <label className="col-sm-6 col-form-label">ID</label>
                                                <div className="col-sm-6">
                                                    <input
                                                      value={user.id}
                                                      readOnly
                                                      type="text" className="form-control"
                                                      placeholder="233"
                                                    />
                                                </div>
                                            </div>
                                            )}
                                            <div className="form-group mb-5 mt-5 row">
                                                <label className="col-sm-6 col-form-label">First Name</label>
                                                <div className="col-sm-6">
                                                    <input
                                                      value={user.firstName}
                                                      onChange={e => setUserProperty('firstName', e)}
                                                      type="text" className="form-control"
                                                      placeholder="Ben"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group mb-5 mt-5 row">
                                                <label className="col-sm-6 col-form-label">Surname</label>
                                                <div className="col-sm-6">
                                                    <input
                                                      value={user.lastName}
                                                      onChange={e => setUserProperty('lastName', e)}
                                                      type="text" className="form-control"
                                                      placeholder="Harwood"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group mb-5 mt-5 row">
                                                <label className="col-sm-6 col-form-label">Username</label>
                                                <div className="col-sm-6">
                                                    <input
                                                      value={user.username}
                                                      onChange={e => setUserProperty('username', e)}
                                                      type="text" className="form-control"
                                                      placeholder="BenH"
                                                      readOnly={!!id}
                                                      required
                                                    />
                                                </div>
                                                {!id && _.find(users, { username: user.username }) ? (
                                                    <label className="col-sm-6 col-form-label error">That username is in use</label>
                                                ) : null}
                                            </div>

                                            {!id ? (
                                                <div className="form-group mb-5 mt-5 row">
                                                    <label className="col-sm-6 col-form-label">Password</label>
                                                    <div className="col-sm-6">
                                                        <input
                                                          value={user.password}
                                                          onChange={e => setUserProperty('password', e)}
                                                          type="password" className="form-control"
                                                          placeholder="Enter a password"
                                                          required
                                                        />
                                                    </div>
                                                </div>
                                            ) : null}

                                            {this.props.id && (
                                            <div className="form-group mb-5 mt-5 row">
                                                <label className="col-sm-6 col-form-label">Reset password</label>
                                                <div className="col-sm-6">
                                                    <button
                                                      data-toggle="modal" data-target="#resetPasswordModal" className="btn btn-link"
                                                      type="button"
                                                    >
                                                                Reset
                                                    </button>
                                                </div>
                                            </div>
                                            )}


                                            <div className="custom-control custom-checkbox">
                                                <Switch
                                                  checked={user.isAdmin}
                                                  onChange={e => setUserProperty('isAdmin', e)}
                                                  type="checkbox" className="custom-control-input" id="customCheck1"
                                                />
                                                <label className="pl-3 custom-control-label" htmlFor="customCheck1">Admin</label>
                                            </div>

                                        </div>
                                        <div className="col-sm-6">
                                            <label className="pl-0 col-sm-6 col-form-label">User is member of Selected Groups</label>
                                            <UserGroupSelect
                                              value={user.groups}
                                              onChange={e => setUserProperty('groups', e)}
                                            />
                                        </div>
                                        <button
                                          disabled={userSaving}
                                          type="submit"
                                          className="hidden"
                                        />
                                    </div>
                                    <div className="card-footer">
                                        <button
                                          disabled={userSaving || !this.valid()} className="btn-lg btn--primary float-right"
                                          type="submit"
                                        >
                                            {userSaving ? 'Saving' : 'Save'}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div
                          className="tab-pane fade" id="pills-documents" role="tabpanel"
                          aria-labelledby="pills-documents-tab"
                        >
                            Documents tab
                        </div>
                        <div
                          className="tab-pane fade" id="pills-correspondence" role="tabpanel"
                          aria-labelledby="pills-correspondence-tab"
                        >
                            Correspondence tab
                        </div>
                        <div
                          className="modal fade bd-example-modal-lg" id="resetPasswordModal" tabIndex="-1"
                          role="dialog" aria-labelledby="resetPasswordModalLabel" aria-hidden="true"
                        >
                            <div className="modal-dialog modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="resetPasswordModalLabel">Reset Password</h5>
                                        <button
                                          type="button" className="close" data-dismiss="modal"
                                          aria-label="Close"
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <form onSubmit={saveUserPassword}>
                                            <div className="form-group row mb-5 mt-5">
                                                <label className="col-sm-3 col-form-label">New password</label>
                                                <div className="col-sm-9">
                                                    <input
                                                      value={user.newPassword}
                                                      onChange={e => setUserProperty('newPassword', e)}
                                                      type="password"
                                                      className="form-control" placeholder="New password"
                                                    />
                                                    {' '}

                                                </div>
                                            </div>
                                            <div className="form-group row mb-5 mt-5">
                                                <label className="col-sm-3 col-form-label">Confirm new password</label>
                                                <div className="col-sm-9">
                                                    <input
                                                      value={user.confirmNewPassword}
                                                      onChange={e => setUserProperty('confirmNewPassword', e)}
                                                      type="password"
                                                      className="form-control" placeholder="Confirm new password"
                                                    />
                                                    {' '}
                                                </div>
                                            </div>

                                            <button
                                              disabled={!validNewPassword || userSaving}
                                              type="submit"
                                              className="hidden"
                                            />
                                        </form>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn--secondary-outline" data-dismiss="modal">CANCEL</button>
                                        <button
                                          onClick={saveUserPassword} disabled={!validNewPassword || userSaving} type="button"
                                          className="btn--primary-outline"
                                        >
                                            RESET PASSWORD
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                          className="modal fade bd-example-modal-lg" id="deleteUserModal" tabIndex="-1"
                          role="dialog" aria-labelledby="deleteUserModalLabel" aria-hidden="true"
                        >
                            <div className="modal-dialog modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="deleteUserModalLabel">REMOVE USER</h5>
                                        <button
                                          type="button" className="close" data-dismiss="modal"
                                          aria-label="Close"
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <p>
                                            Are you sure you wish to delete this user?
                                        </p>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn--secondary-outline" data-dismiss="modal">CANCEL</button>
                                        <button
                                          onClick={deleteUser} disabled={userSaving} type="button"
                                          className="btn--primary-outline"
                                        >
                                            Confirm
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
};

export default hot(module)(withUser(UserPage));
