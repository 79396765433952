import React, { Component } from 'react';

import {
    SortingState,
    IntegratedSorting,
    FilteringState,
    IntegratedFiltering,
    SelectionState,
    IntegratedSelection,
    PagingState,
    IntegratedPaging,
} from '@devexpress/dx-react-grid';


import {
    Grid,
    PagingPanel,
    Table,
    TableFilterRow, TableSelection,
} from '@devexpress/dx-react-grid-material-ui';

import withUserGroups from '../../common/providers/withUserGroups';


const UserGroupSelect = class extends Component {
    static displayName = 'UserGroupSelect';

    static contextTypes = {
        router: propTypes.object.isRequired,
    };

    state = {
        columnOrder: ['name'],
        selection: [],
    };

    changeSelection = (selection) => {
        this.props.onChange(selection.map(s => (
            this.props.userGroups[s]
        )));
    }

    render() {
        const { state: { filters }, props: { value, userGroups: children } } = this;
        const selection = value && value.map(({ id }) => _.findIndex(children, { id }));
        return children ? (

            <Grid
              rows={children}
              columns={[
                  { name: 'name', title: 'User is member of Selected Groups' },
              ]}
            >
                {/* Sorting */}
                <SortingState
                  defaultSorting={[{ columnName: 'type', direction: 'asc' }]}
                />
                <IntegratedSorting />

                <SelectionState
                  selection={selection}
                  onSelectionChange={this.changeSelection}
                />
                <IntegratedSelection />

                {/* Filtering */}
                <FilteringState />
                <IntegratedFiltering />

                <PagingState
                  defaultCurrentPage={0}
                  defaultPageSize={0}
                />
                <IntegratedPaging />

                <Table/>

                {/* Paging */}
                <PagingPanel pageSizes={[10, 15, 20, 1000]} />

                {/* The table header */}
                <TableFilterRow
                  showSortingControls
                />

                <TableSelection showSelectAll />

            </Grid>
        ) : null;
    }
};

UserGroupSelect.propTypes = {
    users: propTypes.array,
};

module.exports = withUserGroups(UserGroupSelect);
