import ReportStore from '../stores/report-store';
import FieldStore from '../stores/field-store';
import data from '../stores/base/_data';

export default (WrappedComponent) => {
    class HOC extends React.Component {
        static displayName = 'withReport';

        constructor(props) {
            super(props);
            ES6Component(this);
            this.listenTo(ReportStore, 'loading', () => {
                this.setState({ reportLoading: true });
            });
            this.listenTo(ReportStore, 'loaded', () => {
                this.setState(this.latestState());
            });
            this.listenTo(ReportStore, 'loadingContent', () => {
                this.setState({ reportLoading: true });
            });
            this.listenTo(ReportStore, 'loadedContent', () => {
                // Update just the cases.
                this.setState({
                    cases: ReportStore.getCases(this.props.id),
                    reportLoading: false
                });
            });
            this.listenTo(FieldStore, 'change', () => {
                this.setState(this.latestState());
            });
            this.state = this.latestState();
        }

        latestState = () => {
            const report = ReportStore.getReport(this.props.id);
            const grouping = _.get(report, 'config.grouping');
            const hasGrouping = grouping && grouping.length;
            const selection = _.get(report, 'config.selection');
            let summaries = _.get(report, 'config.summaries') || [];
            
            if (hasGrouping) {
                summaries = this.addCountForGrouping(summaries, selection);
            }
            
            return {
                allFields: FieldStore.getFields(),
                report,
                selection,
                grouping,
                sorting: _.get(report, 'config.sorting'),
                summaries,
                expandedGroups: this.expandedGroups(ReportStore.getCases(this.props.id), grouping),
                cases: ReportStore.getCases(this.props.id),
                columnWidths: _.map(ReportStore.longestDisplayLengths, (longestDisplayLength, columnName) => {

                    let widthForHeader = 0;
                    const fields = FieldStore.getFields();
                    if (fields) {
                        const field = fields.find(field => field.fieldKey == columnName);
                        if (field && field.label) {
                            // Fixed padding for buttons to the side of column header.
                            widthForHeader = Utils.getTextWidth(field.label, "14px roboto, sans-serif") + 70;
                        }
                    }

                    // Fixed padding for space within the column for the content text.
                    const widthForContent = longestDisplayLength + 18;
                    
                    const width = Math.max(widthForContent, widthForHeader);
                    
                    return { columnName, width };
                }),
                reportLoading: ReportStore.isLoading || !ReportStore.model || FieldStore.isLoading,
            };
        }

        saveReport = () => {
            this.setState({ isSaving: true });
            data.put(`${Project.api}reports/${this.props.id}/config`, {
                grouping: this.state.grouping,
                selection: this.state.selection,
                sorting: this.state.sorting,
                summaries: _.filter(this.state.summaries, summary => summary.alignByColumn !== false),
            }).then(() => {
                toast('Report saved successfully');
                this.setState({ isSaving: false });
            }).catch(() => {
                toast('There was a problem trying to save the report');
                this.setState({ isSaving: false });
            });
        }

        expandedGroups = (cases, grouping) => {
            if (!grouping || grouping.length === 0) {
                return [];
            }
            const res = [];
            this.processExpandedGroups(cases, grouping, res);
            return res;
        }

        processExpandedGroups = (cases, grouping, result, groupingIndex = 0, path = []) => {
            const grouped = _.groupBy(cases, grouping[groupingIndex].columnName);
            _.map(grouped, (group, key) => {
                const groupPath = path.concat([key]);
                result.push(groupPath.join('|'));
                if (groupingIndex + 1 < grouping.length) {
                    this.processExpandedGroups(cases, grouping, result, groupingIndex + 1, groupPath);
                }
            });
        }

        changeExpandedGroups = (expandedGroups) => {
            this.setState({ expandedGroups });
        }


        changeSelection = (hiddenFields) => {
            this.setState({ selection: _.sortBy(_.difference(_.map(this.state.allFields, 'fieldKey'), hiddenFields), (fieldKey) => {
                const index = this.state.selection.indexOf(fieldKey);
                return index === -1 ? Infinity : index;
            }) });
        }

        changeSorting = sorting => this.setState({ sorting });

        changeGrouping = (grouping) => {
            let summaries = [... this.state.summaries];

            // If we have groups, but no createdForGroup summary to count items, add one
            if (grouping && grouping.length > 0
                    && !summaries.some(summary => summary.createdForGroup)) {
                summaries = this.addCountForGrouping(summaries, this.state.selection);
            }

            // If we've just removed all grouping, also remove summaries added for grouping.
            if (!grouping || grouping.length === 0) {
                summaries = summaries.filter(summary => !summary.createdForGroup);
            }

            this.setState({
                summaries,
                grouping,
                expandedGroups: this.expandedGroups(this.state.cases, grouping),
            });
        }

        changeOrdering = ordering => this.setState({ selection: ordering });

        changeSummaries = (summaries) => {
            this.setState({ summaries });
        }

        getColumnWidth = columnName => ReportStore.getColumnWidth(columnName);

        addCountForGrouping = (summaries, selection) => {
            return [
                {
                    // Can count any column, so just pick the first which is displayed.
                    columnName: selection && selection[0],
                    type: 'count',
                    showInGroupFooter: false,
                    alignByColumn: false,
                    createdForGroup: true,
                },
                ...summaries
            ];
        }

        render() {
            const { report, cases, reportLoading, allFields, sorting, selection, grouping, summaries, columnWidths, expandedGroups } = this.state;
            const { changeSelection, saveReport, changeGrouping, changeSorting, changeOrdering, changeSummaries, getColumnWidth, changeExpandedGroups } = this;
            const hiddenFields = allFields && _.difference(_.map(allFields, 'fieldKey'), selection);
            return (
                <WrappedComponent
                  ref="wrappedComponent"
                  users={report}
                  allFields={allFields}
                  hiddenFields={hiddenFields}
                  sorting={sorting}
                  saveReport={saveReport}
                  selection={selection}
                  grouping={grouping}
                  expandedGroups={expandedGroups}
                  summaries={summaries}
                  changeSelection={changeSelection}
                  getColumnWidth={getColumnWidth}
                  changeSorting={changeSorting}
                  changeGrouping={changeGrouping}
                  changeOrdering={changeOrdering}
                  changeSummaries={changeSummaries}
                  changeExpandedGroups={changeExpandedGroups}
                  cases={cases}
                  columnWidths={columnWidths}
                  isSaving={this.state.isSaving}
                  reportLoading={reportLoading}
                  {...this.props}
                />
            );
        }
    }

    return HOC;
};
