import BaseStore from './base/_store';
import data from './base/_data';

const store = Object.assign({}, BaseStore, {
    id: 'whitelabel',
    getWhitelabel() {
        return store.model;
    },
});

const controller = {
    getWhitelabel: () => {
        store.loading();
        // todo: maybe cache
        data.get(`${Project.api}public/whitelabel?host=${window.location.hostname}`)
            .then((res) => {
                store.model = res;
                AsyncStorage.setItem('whitelabel', JSON.stringify(res));
                store.loaded();
            })
            .catch(res => API.ajaxHandler(store, res));
    },
};

store.dispatcherIndex = Dispatcher.register(store, (payload) => {
    const action = payload.action; // this is our action from handleViewAction

    switch (action.actionType) {
        case Actions.GET_WHITELABEL:
            controller.getWhitelabel();
            break;
        default:
    }
});


controller.store = store;
module.exports = controller.store;
