import React, { Component } from 'react';

import {
    SortingState,
    IntegratedSorting,
    FilteringState,
    IntegratedFiltering,
    SelectionState,
    IntegratedSelection,
    PagingState,
    IntegratedPaging,
} from '@devexpress/dx-react-grid';


import {
    Grid,
    PagingPanel,
    Table,
    TableFilterRow, TableSelection, VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';

import withFolders from '../../common/providers/withFolders';
import data from '../../common/stores/base/_data';


import Delay from './Delay';


const FolderSelect = class extends Component {
    static displayName = 'FolderSelect';

    static contextTypes = {
        router: propTypes.object.isRequired,
    };

    state = {
        columnOrder: ['name'],
        selection: [],
    };

    changeSelection = (selection) => {
        this.props.onChange(selection.map(s => (
            this.props.folders[s]
        )));
    }

    render() {
        const { state: { filters }, props: { value, folders: children } } = this;
        const selection = value && value.map(({ id }) => _.findIndex(children, { id }));
        return children ? (

            <Grid
              rows={children}
              columns={[
                  { name: 'name', title: 'User is member of Selected Groups' },
              ]}
            >
                {/* Sorting */}
                <SortingState
                  defaultSorting={[{ columnName: 'name', direction: 'asc' }]}
                />
                <IntegratedSorting />

                <SelectionState
                  selection={selection}
                  onSelectionChange={this.changeSelection}
                />
                <IntegratedSelection />

                {/* Filtering */}
                <FilteringState />
                <IntegratedFiltering />

                <PagingState
                  defaultCurrentPage={0}
                  defaultPageSize={0}
                />
                <IntegratedPaging />

                <VirtualTable
                  height={400}
                />

                {/* Paging */}
                <PagingPanel pageSizes={[10, 15, 20, 1000]} />

                {/* The table header */}
                <TableFilterRow
                  showSortingControls
                />

                <TableSelection showSelectAll />

            </Grid>
        ) : null;
    }
};

FolderSelect.propTypes = {
    folders: propTypes.array,
};

const FolderTable = withFolders(FolderSelect);

const TheComponent = class extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            selection: [],
        };
    }

    save = () => {
        const { state: { selection } } = this;
        this.setState({ isSaving: true });
        const destinationFolderIds = _.map(selection, 'id');
        data.put(`${Project.api}reports/${this.props.reportId}/clone`, {
            destinationFolderIds,
        })
            .then(() => {
                AppActions.getFolders(parseInt(this.props.reportId));
                closeModal();
            });
        closeModal();
    }

    onChange = selection => this.setState({ selection })

    render() {
        const { state: { selection, isSaving } } = this;
        return (
            <div>
                <div className="card card--report">
                    <div className="card card--report">
                        <Delay>
                            <div className="text-center">
                                <Loader/>
                            </div>
                            <FolderTable value={selection} onChange={this.onChange}/>
                        </Delay>
                    </div>
                </div>
                <FormGroup>
                    <div className="text-right">
                        <Button onClick={this.save} className="btn btn--primary" disabled={!selection.length || isSaving}>Save</Button>
                    </div>
                </FormGroup>
            </div>

        );
    }
};

TheComponent.propTypes = {};

module.exports = TheComponent;
