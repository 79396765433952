import BaseStore from './base/_store';
import data from './base/_data';

const store = Object.assign({}, BaseStore, {
    id: 'users',
    getUsers() {
        return store.model;
    },

});

const controller = {
    getUsers: () => {
        store.loading();
        data.get(`${Project.api}users`).then((res) => {
            store.model = res.data;
            AsyncStorage.setItem('users', JSON.stringify(store.model));
            store.loaded();
        }).catch(res => API.ajaxHandler(store, res));
        // setTimeout(() => {
        //     store.model = require('../../tests/pact/responses/admin/users.response').data;
        //     AsyncStorage.setItem('users', JSON.stringify(store.model));
        //     store.loaded();
        // });
    },
    deleteUser: (id) => {
        store.saving();
        data.delete(`${Project.api}users/${id}`).then(() => {
            const index = _.findIndex(store.model, { id });
            if (index !== -1) {
                store.model.splice(index, 1);
            }
            store.saved();
            store.trigger('deleted');
        }).catch(res => API.ajaxHandler(store, res));
        // setTimeout(() => {
        //     const index = _.findIndex(store.model, { id });
        //     if (index !== -1) {
        //         store.model.splice(index, 1);
        //     }
        //     store.saved();
        //     store.trigger('deleted');
        // });
    },
    createUser: (details) => {
        store.saving();
        data.post(`${Project.api}users`, details).then((res) => {
            store.model = store.model || [];
            store.model.push(res);
            store.saved();
        }).catch(res => API.ajaxHandler(store, res));
        // setTimeout(() => {
        //     store.model = store.model || [];
        //     store.model.push(require('../../tests/pact/responses/admin/user-create.response'));
        //     store.saved();
        // });
    },
};

store.dispatcherIndex = Dispatcher.register(store, (payload) => {
    const action = payload.action; // this is our action from handleViewAction

    switch (action.actionType) {
        case Actions.GET_USERS:
            controller.getUsers();
            break;
        case Actions.DELETE_USER:
            controller.deleteUser(action.id);
            break;
        case Actions.CREATE_USER:
            controller.createUser(action.data);
            break;
        default:
    }
});

AsyncStorage.getItem('users', (err, res) => {
    if (res) {
        store.model = JSON.parse(res);
    }
});
controller.store = store;
module.exports = controller.store;
