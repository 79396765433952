import ReportFilters from '../stores/report-filters-store';
import FieldStore from '../stores/field-store';

export default (WrappedComponent) => {
    class HOC extends React.Component {
        static displayName = 'withReportFilters';

        constructor(props) {
            super(props);
            ES6Component(this);
            this.listenTo(ReportFilters, 'change', () => {
                this.setState({
                    filters: ReportFilters.isLoading ? this.state.filters : [].concat(ReportFilters.getReportFilters(this.props.id) || []),
                    filtersLoading: ReportFilters.isLoading,
                    filtersSaving: ReportFilters.isSaving,
                });
            });
            this.listenTo(FieldStore, 'loaded', () => {
                this.setState({
                    filters: ReportFilters.isLoading ? this.state.filters : [].concat(ReportFilters.getReportFilters(this.props.id) || []),
                    filtersLoading: ReportFilters.isLoading,
                    filtersSaving: ReportFilters.isSaving,
                });
            });
            this.state = {
                filters: [].concat(ReportFilters.getReportFilters(this.props.id) || []),
                filtersLoading: ReportFilters.isLoading,
                filtersSaving: ReportFilters.isSaving,
            };
        }

        componentDidMount() {
            if (this.wrappedComponent.onSave) {
                this.listenTo(ReportFilters, 'saved', () => this.wrappedComponent.onSave());
            }
            if (this.wrappedComponent.onDelete) {
                this.listenTo(ReportFilters, 'deleted', this.wrappedComponent.onDelete);
            }
        }

        setReportFilters =(filters) => {
            this.setState({ filters: [].concat(filters) });
        }

        saveReportFilters = () => {
            AppActions.setReportFilters(this.props.id, this.state.filters);
        }

        render() {
            const { filters, filtersLoading, filtersSaving } = this.state;
            const { setReportFilters, saveReportFilters } = this;
            return (
                <WrappedComponent
                  ref={c => this.wrappedComponent = c}
                  filters={filters}
                  filtersLoading={filtersLoading}
                  saveReportFilters={saveReportFilters}
                  filtersSaving={filtersSaving}
                  setReportFilters={setReportFilters}
                  {...this.props}
                />
            );
        }
    }

    return HOC;
};