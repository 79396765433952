import React, { Component } from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import FieldStore from '../../common/stores/field-store';

const ManageFilters = class extends Component {
    static displayName = 'ManageFilters';

    constructor(props) {
        super(props);
        this.filterFields = _.map(FieldStore.getFields(), field => (
            <option key={field.id} value={field.id}>{field.label}</option>
        ));
        this.filterTypes = {
            'STRING': [
                { value: 'CONTAINS_ANY', label: 'contains' },
                { value: 'IS_BLANK', label: 'is empty' },
                { value: 'STARTS_WITH', label: 'starts with' },
                { value: 'ENDS_WITH', label: 'ends with' },
                { value: 'CONTAINS_ALL', label: 'exactly matches' },
            ],
            'NUMBER': [
                { value: 'GREATER_THAN', label: '>' },
                { value: 'GREATER_THAN_OR_EQUAL_TO', label: '>=' },
                { value: 'LESS_THAN', label: '<' },
                { value: 'LESS_THAN_OR_EQUAL_TO', label: '<=' },
                { value: 'MATCHES_EXACTLY', label: '=' },
                { value: 'NOT_EQUAL_TO', label: '!=' },
                { value: 'RANGE', label: 'between' },
            ],
            'DATE': [
                { value: 'BEFORE', label: 'Is before' },
                { value: 'AFTER', label: 'Is after' },
                { value: 'SAME', label: 'Is same as' },
                { value: 'SAME_OR_BEFORE', label: 'Is same as or before' },
                { value: 'SAME_OR_AFTER', label: 'Is same as or after' },
                { value: 'IS_BETWEEN', label: 'Is between' },
                { value: 'IS_BLANK', label: 'is empty' },
            ],
        };
    }

    onChange = (index, value, path, fieldType) => {
        console.log('onChange value', value)
        const filters = _.cloneDeep(this.props.filters);
        _.set(filters[index], path, value);
        this.props.onChange(filters);
    }

    onChangeField = (index, value) => {
        const id = parseInt(value);
        const filters = _.cloneDeep(this.props.filters);
        if (!isNaN(id)) {
            filters[index].field = { id };
        } else {
            filters[index].field = null;
        }
        if (filters[index].type) delete filters[index].type;
        if (filters[index].value) delete filters[index].value;
        this.props.onChange(filters);
    }

    add = () => {
        const filters = _.cloneDeep(this.props.filters);
        filters.push({});
        this.props.onChange(filters);
    }

    remove = (index) => {
        const filters = _.cloneDeep(this.props.filters);
        filters.splice(index, 1);
        this.props.onChange(filters);
    }

    render() {
        const { filters } = this.props;
        return (
            <React.Fragment>
                {_.map(filters, (filter, index) => {
                    const fieldId = _.get(filter, 'field.id');
                    const filterField = _.find(FieldStore.getFields(), { id: fieldId });
                    const { type, value, valueTo } = filter;
                    return (
                        <div className="container" key={index}>
                            <div className="text-right">
                                <a onClick={() => this.remove(index)} className="btn--remove-filter pt-3">
                                    <i className="fas fa-minus-circle"/>
                                    <span className="btn--add-filter"> Remove</span>
                                </a>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-6 col-form-label pl-0">Field</label>
                                <select
                                  className="form-control" value={filterField ? filterField.id : ''}
                                  onChange={e => this.onChangeField(index, Utils.safeParseEventValue(e))}
                                >
                                    <option
                                      value=""
                                      onChange={e => this.onChangeField(index, Utils.safeParseEventValue(e))}
                                    >
                                        Select a field...
                                    </option>
                                    {this.filterFields}
                                </select>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-6 col-form-label pl-0">Type</label>
                                <select
                                  className="form-control" value={type || ''}
                                  disabled={!filterField || !this.filterTypes[filterField.fieldType]}
                                  onChange={e => this.onChange(index, Utils.safeParseEventValue(e), 'type')}
                                >
                                    <option value="">Select a filter type...</option>
                                    {filterField && this.filterTypes[filterField.fieldType] ? _.map(this.filterTypes[filterField.fieldType], filterType => (
                                        <option
                                          key={filterType.value}
                                          value={filterType.value}
                                        >
                                            {filterType.label}
                                        </option>
                                    )) : null}
                                </select>
                            </div>
                            <div className="form-group row pt-3">
                                <div className="custom-control custom-checkbox">
                                    <Switch
                                      checked={filter.negate}
                                      onChange={e => this.onChange(index, Utils.safeParseEventValue(e), 'negate')}
                                      type="checkbox" className="custom-control-input"
                                    />
                                    <label className="pl-3 custom-control-label">Negate</label>
                                </div>
                            </div>
                            {filterField && !(filterField.fieldType === 'DATE' && type === 'IS_BLANK') ? (
                                <React.Fragment>
                                    <div className="form-group row">
                                        <label className="col-sm-12 col-form-label pl-0">Value</label>
                                        {(() => {
                                            switch (filterField.fieldType) {
                                                case 'STRING':
                                                case 'NUMBER':
                                                    if (filterField.fieldType === 'STRING' && type === 'IS_BLANK') return null;
                                                    return (
                                                        <React.Fragment>
                                                            <input
                                                              className="form-control"
                                                              type={(filterField.fieldType === 'STRING' || type === 'MATCHES_EXACTLY' || type === 'NOT_EQUAL_TO') ? 'text' : 'number'}
                                                              value={value || ''}
                                                              disabled={!type}
                                                              onChange={e => this.onChange(index, Utils.safeParseEventValue(e), 'value', filterField.fieldType)}
                                                            />
                                                            {filterField.fieldType === 'NUMBER' && type === 'RANGE' ? (
                                                                <input
                                                                  className="form-control" type="number"
                                                                  value={valueTo || ''}
                                                                  disabled={!type}
                                                                  onChange={e => this.onChange(index, Utils.safeParseEventValue(e), 'valueTo', 'NUMBER')}
                                                                />
                                                            ) : null}
                                                        </React.Fragment>
                                                    );
                                                case 'DATE':
                                                    return (
                                                        <React.Fragment>

                                                            <DatePicker
                                                              className="form-control"
                                                              selected={value}
                                                              onChange={date => this.onChange(index, date, 'value', 'DATE')}
                                                              disabled={!type}
                                                              dateFormat="MMMM d, yyyy"
                                                            />

                                                            {type === 'IS_BETWEEN' ? (

                                                                <DatePicker
                                                                  className="form-control ml-3"
                                                                  selected={valueTo}
                                                                  onChange={date => this.onChange(index, date, 'valueTo', 'DATE')}
                                                                  disabled={!type}
                                                                  dateFormat="MMMM d, yyyy"
                                                                />

                                                            ) : null}
                                                        </React.Fragment>
                                                    );
                                                default:
                                                    return null;
                                            }
                                        })()}
                                    </div>
                                    {filterField.fieldType === 'STRING' ? (
                                        <React.Fragment>
                                            <div className="form-group row pt-3">
                                                <div className="custom-control custom-checkbox">
                                                    <Switch
                                                      checked={filter.negate}
                                                      onChange={e => this.onChange(index, Utils.safeParseEventValue(e), 'negate')}
                                                      type="checkbox" className="custom-control-input"
                                                    />
                                                    <label className="pl-3 custom-control-label">Negate</label>
                                                </div>
                                            </div>
                                            {type !== 'IS_BLANK' ? (
                                                <div className="form-group row">
                                                    <div className="custom-control custom-checkbox">
                                                        <Switch
                                                          checked={filter.caseSensitive}
                                                          onChange={e => this.onChange(index, Utils.safeParseEventValue(e), 'caseSensitive')}
                                                          type="checkbox" className="custom-control-input"
                                                        />
                                                        <label className="pl-3 custom-control-label">
                                                            Case
                                                            sensitive
                                                        </label>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </React.Fragment>
                                    ) : null}
                                </React.Fragment>
                            ) : null}
                        </div>
                    );
                })}
                <div className="text-center pt-4">
                    <a onClick={this.add} className="btn--add-filter">
                        <i className="fas fa-plus-circle"/>
                        <span className="btn--add-filter"> Add filter</span>
                    </a>
                </div>
            </React.Fragment>
        );
    }
};

ManageFilters.propTypes = {};

module.exports = ManageFilters;
