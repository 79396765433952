import UserGroupsStore from '../stores/user-groups-store';

export default (WrappedComponent) => {
    class HOC extends React.Component {
        static displayName = 'withUserGroup';

        constructor(props) {
            super(props);
            ES6Component(this);
            this.listenTo(UserGroupsStore, 'change', () => {
                this.setState({
                    userGroup: { ...(UserGroupsStore.getUserGroup(props.id) || {}) },
                    userGroupLoading: UserGroupsStore.isLoading,
                    userGroupSaving: UserGroupsStore.isSaving,
                });
            });
            this.state = {
                userGroup: { ...(UserGroupsStore.getUserGroup(props.id) || {}) },
                userGroupLoading: UserGroupsStore.isLoading,
                userGroupSaving: UserGroupsStore.isSaving,
            };
        }

        componentDidMount() {
            if (this.wrappedComponent.onSave) {
                this.listenTo(UserGroupsStore, 'saved', () => this.wrappedComponent.onSave(UserGroupsStore.savedItem));
            }
            if (this.wrappedComponent.onDelete) {
                this.listenTo(UserGroupsStore, 'deleted', this.wrappedComponent.onDelete);
            }
        }

        setUserGroupProperty = (path, e) => {
            const userGroup = this.state.userGroup;
            userGroup[path] = Utils.safeParseEventValue(e);
            this.setState({ userGroup });
        }

        setUserGroupLogo = (file, input) => {
            if (file.size > 51200) {
                input.value = '';
                toast('Logo must be 50kB or less');
                return;
            }
            API.getBase64FromFile(file)
                .then((base64) => {
                    const image = new Image();
                    image.onload = () => {
                        if (image.width > 250 || image.height > 250) {
                            input.value = '';
                            toast('Logo cannot be larger than 250x250');
                            return;
                        }
                        const userGroup = this.state.userGroup;
                        userGroup.image = base64;
                        this.setState({ userGroup });
                    };
                    image.src = base64;
                });
        }

        saveUserGroup = (e) => {
            Utils.preventDefault(e);
            if (this.props.id) {
                AppActions.updateUserGroup(this.state.userGroup);
            } else {
                AppActions.createUserGroup(this.state.userGroup);
            }
        }

        deleteUserGroup = (e) => {
            Utils.preventDefault(e);
            AppActions.deleteUserGroup(this.state.userGroup.id);
        }

        isValid = () => {
            const { name, whiteLabelEnabled, hasImage, image } = this.state.userGroup;

            if (!name || (whiteLabelEnabled && !hasImage && !image)) return false;

            return true;
        }

        render() {
            const { userGroup, userGroupLoading, userGroupSaving } = this.state;
            const { saveUserGroup, deleteUserGroup, setUserGroupProperty, setUserGroupLogo, isValid } = this;
            return (
                <WrappedComponent
                  ref={c => this.wrappedComponent = c}
                  userGroup={userGroup}
                  userGroupLoading={userGroupLoading}
                  userGroupSaving={userGroupSaving}
                  saveUserGroup={saveUserGroup}
                  deleteUserGroup={deleteUserGroup}
                  setUserGroupProperty={setUserGroupProperty}
                  setUserGroupLogo={setUserGroupLogo}
                  isValid={isValid}
                  {...this.props}
                />
            );
        }
    }

    return HOC;
};