import React, { Component } from 'react';

import {
    DataTypeProvider,
    FilteringState, IntegratedFiltering,
    IntegratedPaging,
    IntegratedSelection,
    IntegratedSorting,
    IntegratedGrouping,
    GroupingState,
    PagingState,
    SelectionState,
    SortingState,
    SummaryState,
    IntegratedSummary,
    SummaryType,
} from '@devexpress/dx-react-grid';


import {
    Grid,
    PagingPanel,
    VirtualTable,
    Table,
    TableFilterRow,
    TableHeaderRow,
    TableGroupRow,
    GroupingPanel,
    DragDropProvider,
    Toolbar,
    TableColumnVisibility,
    TableSelection,
    TableColumnReordering,
    TableColumnResizing,
    TableSummaryRow,
} from '@devexpress/dx-react-grid-material-ui';

import withReport from '../../common/providers/withReport';
import data from '../../common/stores/base/_data';
import AccountStore from '../../common/stores/account-store';
import FolderSelect from './FolderSelect';
import SummaryChooser from './SummaryChooser';
import ColumnChooser from './ColumnChooser';
import Delay from './Delay';
import { openModal } from '../project/modals';
import UsersTable from './UsersTable';

const CURRENCY_FIELDS = [
    'adjusterPrev', 'adjusterThisMonth', 'totgal',
    'totgoac', 'claimPrevious', 'claimThisMonth', 'caNuFee', 'handling', 'feeThisMonth',
    'feeTotal', 'lastMonthsReserve', 'movement', 'totNBalOfReserve', 'totGpp', 'totGPR',
    'total', 'totGPrelimReserve', 'totalIncurred', 'totalIncurredIncludingFees', 'newTotal',
    'feeBalance',
];

const CustomFilter = props => (
    <TableFilterRow.Cell {...props} className="filter"/>
);
const rowStyle = {
    cursor: 'pointer',
};

const GroupCellContent = ({ column, row, isDate, dateGroupingType }) => {
    let format;
    if (isDate) {
        switch (dateGroupingType[column.name]) {
            case 'Day':
            default:
                format = 'Do MMM YYYY';
                break;
            case 'Month':
                format = 'MMM YYYY';
                break;
            case 'Year':
                format = 'YYYY';
                break;
        }
    }
    return (
        <span>
            {column.title}
        :
            {' '}
            <strong>
                {isDate ? row.value ? moment(row.value, row.value.length === 10 ? 'DD/MM/YYYY' : null).format(format) : 'No date' : row.value}
            </strong>
        </span>
    );
};

const CurrencyFormatter = ({ value }) => `£${value ? value.toFixed(2) : '0.00'}`;

const CurrencyTypeProvider = props => (
    <DataTypeProvider
      formatterComponent={CurrencyFormatter}
      {...props}
    />
);

const GroupingPanelItem = (props, groupClicked, dateGroupingType, dateColumns) => {
    const item = _.cloneDeep(props.item);

    if (dateColumns.includes(item.column.name)) {
        switch (dateGroupingType[item.column.name]) {
            case 'Month':
                item.column.title += ' (Month)';
                break;
            case 'Year':
                item.column.title += ' (Year)';
                break;
            default:
                break;
        }
    }

    return (
        <div role="button" onClick={() => groupClicked(props)}>
            <GroupingPanel.Item {...props} item={item} />
        </div>
    );
};

const ReportTable = class extends Component {
    static displayName = 'ReportTable';

    static contextTypes = {
        router: propTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            columnOrder: ['type', 'name', 'id', 'download'],
            grouping: [],
            sorting: [],
            dateGroupingType: {},
            dateColumns: this.getDateColumns(this.props.allFields),
        };
    }

    componentDidMount() {
        AppActions.getReport(this.props.id);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.id !== this.props.id) {
            this.state.filters = [];
            this.state.selection = [];
            AppActions.getReport(nextProps.id);
        }
        if (nextProps.allFields !== this.props.allFields) {
            this.setState({ dateColumns: this.getDateColumns(nextProps.allFields) });
        }
    }

    navigate = (id) => {
        const { context: { router: { history: { push } } } } = this;
        push(`/reports/${this.props.id}/cases/${id}`);
    };

    renderRow = props => (
        <Table.Row
          className="table-row"
          tableRow={props.tableRow}
                // eslint-disable-next-line no-alert
          onClick={() => {
              this.navigate(props.row.caInCaseId);
          }}
          style={rowStyle}
        >
            {props.children}
        </Table.Row>
    )

    getDateColumns = allFields => _.map(_.filter(allFields, { fieldType: 'DATE' }), 'fieldKey');

    defaultCompare = (a, b) => ((a < b) ? -1 : 1)

    dateCompare = (a, b) => ((new Date(a) < new Date(b)) ? -1 : 1);

    dateCriteria = (value, columnName) => {
        let startOf;
        switch (this.state.dateGroupingType[columnName]) {
            case 'Day':
            default:
                startOf = 'day';
                break;
            case 'Month':
                startOf = 'month';
                break;
            case 'Year':
                startOf = 'year';
                break;
        }
        return {
            key: value ? moment(value, value.length === 10 ? 'DD/MM/YYYY' : null).startOf(startOf).valueOf() : null,
        };
    }

    getCompare = (type) => {
        switch (type) {
            case 'DATE':
                return this.dateCompare;
            default:
                return this.defaultCompare;
        }
    }

    changeSelectedItems = (selectedItems) => {
        this.setState({ selectedItems });
    }

    groupClicked = (props) => {
        const { dateColumns } = this.state;
        const columnName = props.item.column.name;
        if (dateColumns.includes(props.item.column.name)) {
            const dateGroupingType = this.state.dateGroupingType;
            switch (dateGroupingType[columnName]) {
                case 'Year':
                    dateGroupingType[columnName] = 'Day';
                    break;
                case 'Month':
                    dateGroupingType[columnName] = 'Year';
                    break;
                case 'Day':
                default:
                    dateGroupingType[columnName] = 'Month';
                    break;
            }
            this.setState({ dateGroupingType });
        }
    }


    render() {
        const {
            state: { filters, selectedItems, dateGroupingType, dateColumns },
            props: {
                isSaving, getColumnWidth, grouping, hiddenFields, sorting, cases, reportLoading,
                allFields, selection, summaries, expandedGroups,
            },
        } = this;
        const children = cases;

        const columnWidths = this.state.columnWidths || this.props.columnWidths || [];

        const columns = _.intersectionWith(allFields, columnWidths, (field, columnWidth) => field.fieldKey === columnWidth.columnName);

        const groupColumnExtensions = dateColumns.map(columnName => ({
            columnName,
            criteria: value => this.dateCriteria(value, columnName),
        }));
        const isAdmin = AccountStore.getUser().isAdmin;
        const isGrouping = this.props.grouping && this.props.grouping.length;
        // const isAdmin = false;
        return children && children.length ? (

            <div style={reportLoading || isSaving ? { opacity: 0.5 } : null}>
                {isAdmin ? <Button className="btn btn--primary btn--save" style={{ position: 'absolute', top: 0 }} onClick={this.props.saveReport}>Save report</Button> : null}
                <Grid
                  rows={children}
                  columns={columns.map(({ fieldKey, label }) => ({ name: fieldKey, title: label }))}
                >

                    {/* Sorting */}
                    <SortingState
                      sorting={sorting}
                      onSortingChange={this.props.changeSorting}
                    />
                    <IntegratedSorting
                      columnExtensions={allFields.map(f => (
                          { columnName: f.fieldKey, compare: this.getCompare(f.fieldType) }
                      ))}
                    />

                    {/* Selection */}
                    {/* <SelectionState
                      selection={selectedItems}
                      onSelectionChange={this.changeSelectedItems}
                    />
                    <IntegratedSelection /> */}

                    {/* Filtering */}
                    <FilteringState/>
                    <IntegratedFiltering/>
                    {/* Grouping */}
                    <DragDropProvider/>

                    <CurrencyTypeProvider
                      for={CURRENCY_FIELDS}
                    />

                    <GroupingState
                      grouping={grouping}
                      onGroupingChange={this.props.changeGrouping}
                      expandedGroups={expandedGroups}
                      onExpandedGroupsChange={this.props.changeExpandedGroups}
                    />
                    <SummaryState
                      totalItems={summaries}
                      groupItems={(grouping && grouping.length > 0)
                        ? _.map(summaries, summary => ({
                          showInGroupFooter: false,
                          alignByColumn: true,
                          ...summary
                        }))
                        : []
                      }
                    />
                    <IntegratedGrouping
                      columnExtensions={groupColumnExtensions}
                    />
                    <IntegratedSummary
                      calculator={(type, rows, getValue) => {
                          const res = IntegratedSummary.defaultCalculator(type, rows, getValue);
                          return res && parseFloat(res);
                      }}
                    />
                    <PagingState
                      defaultCurrentPage={0}
                      defaultPageSize={1000}
                    />
                    <IntegratedPaging />

                    <VirtualTable
                      estimatedRowHeight={21}
                      height={Math.max(
                        Math.min(
                          document.body.clientHeight - (isGrouping ? 90 : 105),
                          (children.length * 48) + (isGrouping ? 140 : 150)
                        ) - (isGrouping ? 50 : 85),
                        200
                      )}
                      rowComponent={this.renderRow}
                      columnExtensions={_.map(selection, columnName => ({
                          columnName,
                          wordWrapEnabled: false,
                      }))}
                    />

                    <DataTypeProvider
                      formatterComponent={Utils.dateFormatter}
                      for={dateColumns}
                    />

                    {/* Paging */}
                    <PagingPanel />

                    {/* <TableColumnResizing defaultColumnWidths={columnWidths} /> */}

                    <TableColumnResizing
                      columnWidths={columnWidths}
                      resizingMode="widget"
                      onColumnWidthsChange={(cw) => {
                          this.setState({ columnWidths: cw });
                      }}
                    />


                    {/* The table header */}
                    {isAdmin ? <TableHeaderRow showSortingControls showGroupingControls/>
                        : <TableHeaderRow/>}
                    <TableFilterRow
                      cellComponent={CustomFilter}
                      showSortingControls
                    />
                    {/* <TableSelection showSelectAll /> */}
                    <TableGroupRow
                      // Show the date columns when they're being grouped (normally a grouped column is hidden.)
                      columnExtensions={dateColumns.map(columnName => (
                          { columnName, showWhenGrouped: true }
                      ))}
                      contentComponent={({ column, row }) => GroupCellContent({
                          column,
                          row,
                          isDate: dateColumns.indexOf(column.name) !== -1,
                          dateGroupingType,
                      })}
                    />
                    <TableColumnVisibility
                      hiddenColumnNames={hiddenFields.concat(['caInCaseId'])}
                      onHiddenColumnNamesChange={this.props.changeSelection}
                    />
                    <Toolbar/>
                    {isAdmin && (
                        <SummaryChooser
                          selection={selection} summaries={summaries || []}
                          onChange={this.props.changeSummaries}
                        />
                    )}
                    {isAdmin && (
                        <ColumnChooser
                          selection={selection} hiddenFields={hiddenFields} changeSelection={this.props.changeSelection}
                          changeOrdering={this.props.changeOrdering} allFields={allFields}
                        />
                    )}
                    {
                        isAdmin && (
                            <GroupingPanel showSortingControls showGroupingControls itemComponent={props => GroupingPanelItem(props, this.groupClicked, dateGroupingType, dateColumns)} />
                        )
                    }

                    <TableColumnReordering order={selection} onOrderChange={this.props.changeOrdering}/>

                    {!isGrouping && <TableSummaryRow/>}
                </Grid>
            </div>
        )
            : (
                <div className="container-fluid text-center">
                    {reportLoading ? <Loader/> : (
                        <div className="row pb-3">
                            <div className="col-sm-12">
                                <img alt="Empty Folder" className="mb-5" src="/images/empty_folder_Icon_.svg"/>
                                <h1 className="h1--secondary">{children && !children.length ? 'No cases found' : 'Could not load report'}</h1>
                            </div>
                        </div>
                    )}
                </div>
            );
    }
};

ReportTable.propTypes = {};

module.exports = withReport(ReportTable);
