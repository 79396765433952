import './project/polyfil';
import './project/libs';
import './project/api';
import './project/project-components';
import './styles/styles.scss';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ToastMessages from './project/toast';

import routes from './routes';

import WhitelabelStore from '../common/stores/whitelabel-store';

const rootElement = document.getElementById('app');


if (typeof whitelabel !== 'undefined') {
    document.body.classList.add(whitelabel);
    global.whitelabel = whitelabel;
}

// Render the React application to the DOM
AsyncStorage.multiGet(['t', 'whitelabel'], (err, res) => {
    const browserHistory = createBrowserHistory();

    const token = res[0][1];
    if (token) {
        AppActions.setToken(JSON.parse(token));
    }

    const whitelabel = res[1][1];
    if (whitelabel) {
        WhitelabelStore.model = JSON.parse(whitelabel);
    }

    setTimeout(() => {
        // todo: move to util to decide if url is public
        if (document.location.pathname !== '/' && !token) {
            const { redirect } = Utils.fromParam();
            browserHistory.replace(`/?redirect=${redirect || encodeURIComponent(document.location.pathname)}`);
        }

        ReactDOM.render(
            <Router history={browserHistory}>{routes}</Router>,
            rootElement,
        );
    }, 1);
});
// Setup for toast messages
ReactDOM.render(<ToastMessages />, document.getElementById('toast'));
