import UserGroupsStore from '../stores/user-groups-store';

export default (WrappedComponent) => {
    class HOC extends React.Component {
        static displayName = 'withUserGroups';

        constructor(props) {
            super(props);
            ES6Component(this);
            this.listenTo(UserGroupsStore, 'change', () => {
                this.setState({
                    userGroups: UserGroupsStore.getUserGroups(),
                    userGroupsLoading: UserGroupsStore.isLoading,
                });
            });
            this.state = {
                userGroups: UserGroupsStore.getUserGroups(),
                userGroupsLoading: UserGroupsStore.isLoading,
            };
        }

        render() {
            const { userGroups, userGroupsLoading } = this.state;
            return (
                <WrappedComponent
                  ref={c => this.wrappedComponent = c}
                  userGroups={userGroups}
                  userGroupsLoading={userGroupsLoading}
                  {...this.props}
                />
            );
        }
    }

    return HOC;
};