import _ from 'lodash';

import data from '../stores/base/_data';

module.exports = window.Utils = Object.assign({}, require('./base/_utils'), {
    decimalPlaces: (value) => {
        if (!value) return 0;
        if (Math.floor(value) === value) return 0;
        const parts = value.toString().split('.');
        return (parts[1] && parts[1].length) || 0;
    },
    isGEZero: num => typeof num === 'number' && num >= 0,
    isValidPercentage: num => typeof num === 'number' && num >= 0 && num <= 100,
    scrollToTop /* istanbul ignore next */: (timeout = 500) => {
        $('html,body').animate({ scrollTop: 0 }, timeout);
    },
    scrollToElement   /* istanbul ignore next */: (selector, timeout = 500) => {
        $('html,body').animate({ scrollTop: $(selector).offset().top }, timeout);
    },
    isInteger: num => typeof num === 'number' && Math.floor(num) === num,
    fieldShouldShowError: (errors, touched, path, touchPath) => {
        if (!errors || !path) {
            return false;
        }
        return _.get(errors, path) && _.get(touched, touchPath || path);
    },
    // TODO move into a routeHelper type thing
    routeToApplication: (history, quote) => {
        data.get(`${Project.api}document/documents/application/${quote.applicationId}`)
            .then((documents) => {
                if (quote.companyEid) {
                    return data.get(`${Project.api}company/companies/${quote.companyEid}`)
                        .then((company) => {
                            history.replace('/application', { quote, company, documents });
                        });
                }
                // This is supposed to be impossible.. a quote cannot have documents but no company
                if (documents && documents.length) {
                    console.warn('WARNING: Application found with documents but no company');
                }
                history.replace('/application', { quote });
            })
            .catch((err) => {
                // TODO handle better
                console.error('Failed to route to application', err);
                history.replace('/dashboard');
            });
    },
    isFiltersValid: (filters, fields) => _.every(filters, (filter) => {
        if (!filter.type || !_.get(filter, 'field.id')) return false;

        const filterField = _.find(fields, { id: _.get(filter, 'field.id') });
        switch (filterField.fieldType) {
            case 'STRING':
                if (filter.type !== 'IS_BLANK' && !filter.value) return false;
                break;
            case 'DATE':
                if (!filter.value && filter.type !== 'IS_BLANK') return false;
                if (filter.type === 'IS_BETWEEN' && (!filter.valueTo || moment(filter.value).isSameOrAfter(moment(filter.valueTo)))) return false;
                break;
            case 'NUMBER':
                if (filter.type === 'MATCHES_EXACTLY') {
                    return !!filter.value;
                }
                if (!filter.value || isNaN(filter.value)) return false;
                if (filter.type === 'RANGE' && (!filter.valueTo || isNaN(filter.valueTo) || filter.value >= filter.valueTo)) return false;
                break;
            default:
                return false;
        }

        return true;
    }),
    tryDownload: (id, name) => {
        let filename;
        fetch(`${Project.api}reports/${id}/download`, {
            headers: {
                AUTHORIZATION: `Bearer ${data.token}`,
            },
        })
            .then((res) => {
                if (!res.ok) throw res;
                const contentDisposition = res.headers.get('Content-Disposition');
                filename = contentDisposition ? contentDisposition.split(';')[1].split('=')[1].slice(1, -1) : `${name}.xlsx`;
                return res.blob();
            })
            .then((res) => {
                const url = window.URL.createObjectURL(res);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${filename}`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((err) => {
                console.error(err);
                if (err.status === 403) {
                    toast('You do not have permission to download this report.');
                    return;
                }
                toast('Failed to download report.');
            });
    },
    /**
     * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
     * 
     * @param {String} text The text to be rendered.
     * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
     * 
     * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
     */
    getTextWidth: (text, font) => {
        // Re-use canvas object for better repeat performance.
        const canvas = window.canvasForMetrics || (window.canvasForMetrics = document.createElement('canvas'));
        const context = canvas.getContext('2d');
        context.font = font;
        const metrics = context.measureText(text);
        return Math.ceil(metrics.width);
    },
    dateFormatter: ({ value }) => (value ? moment(value, value.length === 10 ? 'DD/MM/YYYY' : null).format('Do MMM YYYY') : ''),
    
    formatCaseForStore: (theCase, store) => {
        _.each(theCase, (val, key) => {
            if (/^\d{4}\-\d{2}\-\d{2}\D\d{2}\:\d{2}\:\d{2}\.\d{3}\+\d{4}$/.test(val)) {
                // Value is a long datetime string (of the form "2019-12-20T00:00:00.000+0000"), which is shown formatted
                val = Utils.dateFormatter({ val });
            }
            const displayLength = Utils.getTextWidth(`${val}`, "14px roboto, sans-serif");
            if (!store.longestDisplayLengths[key]) {
                store.longestDisplayLengths[key] = displayLength;
            } if (displayLength > store.longestDisplayLengths[key]) {
                store.longestDisplayLengths[key] = displayLength;
            }
        });
    },
});
