import React from 'react';
import {
    Plugin,
    Template,
    TemplatePlaceholder,
} from '@devexpress/dx-react-core';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import Popover from '@material-ui/core/Popover';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

const pluginDependencies = [
    { name: 'Toolbar' },
];

const ToggleButton = ({
    onToggle, title,
    buttonRef, active,
    ...restProps
}) => (
    <Tooltip
      title={title}
      placement="bottom"
      enterDelay={300}
    >
        <IconButton
          onClick={onToggle}
          buttonRef={buttonRef}
          {...restProps}
        >
            <VisibilityOff />
        </IconButton>
    </Tooltip>
);

const Overlay = ({
    visible, onHide, children, target, ...restProps
}) => (
    <Popover
      open={visible}
      anchorEl={target}
      onClose={onHide}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      {...restProps}
    >
        {children}
    </Popover>
);

export default class ColumnChooser extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { visible: false, hiddenSelected: [], visibleSelected: [] };
        this.labels = _.keyBy(props.allFields, 'fieldKey');
    }


  setButtonRef = (button) => {
      this.button = button;
  }

  handleToggle = () => {
      const { visible } = this.state;
      this.setState({ visible: !visible });
  }

  handleHide = () => {
      this.setState({ visible: false });
  }

  handleRowClick = (name, hidden) => {
      const selected = hidden ? this.state.hiddenSelected : this.state.visibleSelected;
      const selectedIndex = selected.indexOf(name);
      let newSelected = [];

      if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
              selected.slice(0, selectedIndex),
              selected.slice(selectedIndex + 1),
          );
      }

      if (hidden) {
          this.setState({ hiddenSelected: newSelected, visibleSelected: [] });
          return;
      }

      this.setState({ hiddenSelected: [], visibleSelected: newSelected });
  }

  isSelected = (name, hidden) => {
      const selected = hidden ? this.state.hiddenSelected : this.state.visibleSelected;
      return selected.indexOf(name) !== -1;
  }

  show = () => {
      const selected = this.state.hiddenSelected;
      if (!selected.length) return;

      const hiddenFields = _.difference(this.props.hiddenFields, selected);
      this.props.changeSelection(hiddenFields);
      this.setState({ visibleSelected: [], hiddenSelected: [] });
  }

  hide = () => {
      const selected = this.state.visibleSelected;
      if (!selected.length) return;

      const hiddenFields = this.props.hiddenFields.concat(selected);
      this.props.changeSelection(hiddenFields);
      this.setState({ visibleSelected: [], hiddenSelected: [] });
  }

  move = (up) => {
      const selected = this.state.visibleSelected;
      if (!selected.length) return;

      const selection = this.props.selection;

      // Order the selected by current selection ordering
      const orderedSelected = _.sortBy(selected, columnName => selection.indexOf(columnName) * (up ? 1 : -1));

      _.each(orderedSelected, (columnName) => {
          const index = selection.indexOf(columnName);
          if (up ? index === 0 : index === selection.length - 1) return;

          // Check whether previous / next index contains a selected field
          if (orderedSelected.indexOf(selection[up ? index - 1 : index + 1]) !== -1) return;

          // Move it up / down
          selection.splice(index, 1);
          selection.splice(up ? index - 1 : index + 1, 0, columnName);
      });

      this.props.changeOrdering(selection);
  }

  moveUp = () => this.move(true)

  moveDown = () => this.move(false)

  moveTo = (top) => {
      const selected = this.state.visibleSelected;
      if (!selected.length) return;

      // Order the selected by current selection ordering
      const orderedSelected = _.sortBy(selected, columnName => this.props.selection.indexOf(columnName));

      // Get the selection without the selected columns
      let selection = _.difference(this.props.selection, orderedSelected);

      // Insert selected columns at top
      selection = top ? orderedSelected.concat(selection) : selection.concat(orderedSelected);

      this.props.changeOrdering(selection);
  }

  moveToTop = () => this.moveTo(true)

  moveToBottom = () => this.moveTo(false)

  render() {
      const { selection, hiddenFields } = this.props;
      const { visible, hiddenSelected, visibleSelected } = this.state;
      return (
          <Plugin
            name="ColumnChooser"
            dependencies={pluginDependencies}
          >
              <Template name="toolbarContent">
                  <TemplatePlaceholder />
                  <React.Fragment>
                      <ToggleButton
                        onToggle={this.handleToggle}
                        active={visible}
                        buttonRef={this.setButtonRef}
                        title="Show Column Chooser"
                      />
                      <Overlay
                        visible={visible}
                        target={this.button}
                        onHide={this.handleHide}
                      >
                          <Row className="align-items-start p-3" style={{ minWidth: 600 }}>
                              <Flex className="pr-1">
                                  <Row>
                                      <IconButton onClick={this.show} disabled={!hiddenSelected.length}><VisibilityIcon /></IconButton>
                                  </Row>
                                  <label>Hidden</label>
                                  <TableContainer>
                                      <Table size="small">
                                          <TableBody>
                                              {_.map(hiddenFields, columnName => (
                                                  <TableRow
                                                    hover
                                                    key={columnName}
                                                    onClick={() => this.handleRowClick(columnName, true)}
                                                    role="checkbox"
                                                    aria-checked={hiddenSelected[columnName]}
                                                  >
                                                      <TableCell padding="checkbox">
                                                          <Checkbox
                                                            checked={this.isSelected(columnName, true)}
                                                          />
                                                      </TableCell>
                                                      <TableCell
                                                        component="th" scope="row" padding="none"
                                                        className="clickable"
                                                      >
                                                          {this.labels[columnName].label || columnName}
                                                      </TableCell>
                                                  </TableRow>
                                              ))}
                                          </TableBody>
                                      </Table>
                                  </TableContainer>
                              </Flex>
                              <Flex className="pl-1">
                                  <Row>
                                      <IconButton onClick={this.hide} disabled={!visibleSelected.length}><VisibilityOff /></IconButton>
                                      <IconButton onClick={this.moveUp} disabled={!visibleSelected.length}><ArrowUpwardIcon /></IconButton>
                                      <IconButton onClick={this.moveDown} disabled={!visibleSelected.length}><ArrowDownwardIcon /></IconButton>
                                      <IconButton onClick={this.moveToTop} disabled={!visibleSelected.length}><VerticalAlignTopIcon /></IconButton>
                                      <IconButton onClick={this.moveToBottom} disabled={!visibleSelected.length}><VerticalAlignBottomIcon /></IconButton>
                                  </Row>
                                  <label>Visible</label>
                                  <TableContainer>
                                      <Table size="small">
                                          <TableBody>
                                              {_.map(selection, columnName => (
                                                  <TableRow
                                                    hover
                                                    key={columnName}
                                                    onClick={() => this.handleRowClick(columnName, false)}
                                                    role="checkbox"
                                                    aria-checked={visibleSelected[columnName]}
                                                  >
                                                      <TableCell padding="checkbox">
                                                          <Checkbox
                                                            checked={this.isSelected(columnName, false)}
                                                          />
                                                      </TableCell>
                                                      <TableCell
                                                        component="th" scope="row" padding="none"
                                                        className="clickable"
                                                      >
                                                          {this.labels[columnName].label || columnName}
                                                      </TableCell>
                                                  </TableRow>
                                              ))}
                                          </TableBody>
                                      </Table>
                                  </TableContainer>
                              </Flex>
                          </Row>
                      </Overlay>
                  </React.Fragment>
              </Template>
          </Plugin>
      );
  }
}
