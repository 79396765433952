import React, { Component } from 'react';

import withReportFilters from '../../common/providers/withReportFilters';
import ManageFilters from './ManageFilters';
import FieldStore from '../../common/stores/field-store';

const ManageReportFilters = class extends Component {
  static displayName = 'ManageReportFilters';

  constructor(props) {
      super(props);
      AppActions.getReportFilters(props.id);
  }

  componentWillUpdate(nextProps) {
      if (nextProps.id !== this.props.id) {
          AppActions.getReportFilters(nextProps.id);
      }
  }

  onSave = (group) => {
      this.props.onSave && this.props.onSave();
  }

  render() {
      const { filters, filtersLoading, filtersSaving, setReportFilters, saveReportFilters } = this.props;
      return (
          <div>
              {filtersLoading ? (
                  <Loader/>
              ) : (
                  <ManageFilters filters={filters} onChange={setReportFilters} />
              )}
              <div className="modal-footer">
                  <button type="button" className="btn--secondary-outline" data-dismiss="modal">CANCEL</button>
                  <button
                    onClick={saveReportFilters} disabled={filtersSaving || !Utils.isFiltersValid(filters, FieldStore.getFields())} type="button"
                    className="btn--primary-outline"
                  >
                      {filtersSaving ? 'Saving' : 'Save'}
                  </button>
              </div>
          </div>
      );
  }
};

ManageReportFilters.propTypes = {};

module.exports = withReportFilters(ManageReportFilters);
