import BaseStore from './base/_store';
import data from './base/_data';
import Utils from '../utils/utils';
import Actions from '../dispatcher/action-constants';

const store = Object.assign({}, BaseStore, {
    id: 'report',
    getReport(id) {
        if (store.model && store.model.id === parseInt(id)) return store.model;
        return null;
    },
    getColumnWidth(id) {
        return store.longestDisplayLengths[id];
    },
    getCases(id) {
        if (store.model && store.model.id === parseInt(id)) return store.model.cases;
        return null;
    },

    loadingContentOnly() {
        this.hasLoaded = false;
        this.isLoading = true;
        this.trigger('loadingContent');
    },
    loadedContentOnly() {
        this.hasLoaded = true;
        this.isLoading = false;
        this.trigger('loadedContent');
    },
});

function calcLongestDisplayLengths(cases) {
    const longestDisplayLengths = {};

    // Iterate through the key-value pairs, finding the longest display length for each column
    _.each(cases, (theCase) => {
        _.each(theCase, (val, key) => {
            if (/^\d{4}\-\d{2}\-\d{2}\D\d{2}\:\d{2}\:\d{2}\.\d{3}\+\d{4}$/.test(val)) {
                // Value is a long datetime string (of the form "2019-12-20T00:00:00.000+0000"), which is shown formatted
                val = Utils.dateFormatter({ val });
            }
            const displayLength = Utils.getTextWidth(`${val}`, "14px roboto, sans-serif");
            if (!longestDisplayLengths[key]) {
                longestDisplayLengths[key] = displayLength;
            } if (displayLength > longestDisplayLengths[key]) {
                longestDisplayLengths[key] = displayLength;
            }
        });
    });

    return longestDisplayLengths;
}

const controller = {
    getReport: (id) => {
        store.loading();

        data.get(`${Project.api}reports/${id}`)
            .then((res) => {
                store.model = res;
                store.longestDisplayLengths = calcLongestDisplayLengths(res.cases);
                store.model.id = parseInt(id);
                store.loaded();
            })
            .catch(res => API.ajaxHandler(store, res));
    },
    getReportContentsOnly: (id) => {
        store.loadingContentOnly();

        data.get(`${Project.api}reports/${id}`)
            .then((res) => {
                // Only set the cases property, leave other values as they were.
                store.model.cases = res.cases;
                store.longestDisplayLengths = calcLongestDisplayLengths(res.cases);
                store.model.id = parseInt(id);
                store.loadedContentOnly();
            })
            .catch(res => API.ajaxHandler(store, res));
    },
};

store.dispatcherIndex = Dispatcher.register(store, (payload) => {
    const action = payload.action; // this is our action from handleViewAction

    switch (action.actionType) {
        case Actions.GET_REPORT:
            controller.getReport(action.id);
            break;
        case Actions.GET_REPORT_CONTENTS:
            controller.getReportContentsOnly(action.id);
            break;
        case Actions.SET_REPORT_FILTERS:
            console.log('SET_REPORT_FILTERS at report-store');
            break;
        default:
    }
});


controller.store = store;
module.exports = controller.store;
