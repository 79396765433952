/**
 * Created by kylejohnson on 21/08/2016.
 */
import React from 'react';
import FolderTable from '../FolderTable';
import ManageFolderPermissions from '../ManageFolderPermissions';
import AccountStore from '../../../common/stores/account-store';
import withFolderNavigation from '../../../common/providers/withFolderNavigation';
import ManageFolderFilters from '../ManageFolderFilters';

const DashboardPage = class extends React.Component {
    displayName = 'DashboardPage';

    state = {};

    static contextTypes = {
        router: propTypes.object.isRequired,
    };

    componentDidMount() {
        $('#permissions-modal').on('hidden.bs.modal', () => {
            this.setState({ showFolderPermissions: false });
        });
        $('#filters-modal').on('hidden.bs.modal', () => {
            this.setState({ showFolderFilters: false });
        });
        ES6Component(this);
        this.listenTo(AccountStore, 'change', () => this.forceUpdate());
        this.listenTo(AccountStore, 'deleted', (parent) => {
            const { context: { router: { history, route: { match: { params: { redirect } } } } } } = this;
            $('#delete-folder-modal').modal('hide');
            $('#delete-report-modal').modal('hide');

            if (parent) {
                history.replace(`/folders/${parent}`);
            } else {
                history.replace('/dashboard');
            }
        });
        this.listenTo(AccountStore, 'folder-created', (id) => {
            $('#create-folder-modal').modal('hide');
            const { context: { router: { history, route: { match: { params: { redirect } } } } } } = this;
            this.setState({ folderName: null });
            history.push(`/folders/${id}`);
        });
        this.listenTo(AccountStore, 'folder-updated', (id) => {
            $('#create-folder-modal').modal('hide');
            this.setState({ folderName: null });
        });
        this.listenTo(AccountStore, 'report-created', (id) => {
            $('#create-report-modal').modal('hide');
            this.setState({ reportName: null });
            const { context: { router: { history, route: { match: { params: { redirect } } } } } } = this;
            history.push(`/reports/${id}`);
        });
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (nextProps.id !== this.props.id) {
            this.state.showFolderPermissions = false;
        }
    }

    onFolderPermissionsSave = () => {
        $('#permissions-modal').modal('hide');
        this.setState({ showFolderPermissions: false });
    }

    showFolderPermissions = () => {
        this.setState({ showFolderPermissions: true }, () => {
            $('#permissions-modal').modal('show');
        });
    };

    onFolderFiltersSave = () => {
        $('#filters-modal').modal('hide');
        this.setState({ showFolderFilters: false });
    }

    showFilters = () => {
        this.setState({ showFolderFilters: true }, () => {
            $('#filters-modal').modal('show');
        });
    };

    showDeleteFolder = () => {
        $('#delete-folder-modal').modal('show');
    };

    showCreateFolder = () => {
        this.setState({ folderName: null, editFolder: false }, () => {
            $('#create-folder-modal').modal('show');
        });
    };

    showCreateReport = () => {
        this.setState({ reportName: null }, () => {
            $('#create-report-modal').modal('show');
        });
    };

    showEditFolder = () => {
        this.setState({ folderName: _.last(this.props.breadcrumb).name, editFolder: true }, () => {
            $('#create-folder-modal').modal('show');
        });
    }

    createFolder = (e) => {
        Utils.preventDefault(e);
        if (this.state.folderName) {
            AppActions.createFolder({
                parentId: this.props.node,
                name: this.state.folderName,
            });
        }
    };

    createReport = (e) => {
        Utils.preventDefault(e);
        AppActions.createReport({
            folderId: this.props.node,
            name: this.state.reportName,
        });
    };

    deleteFolder = (e) => {
        Utils.preventDefault(e);
        AppActions.deleteFolder(this.props.node);
    };

    editFolder = (e) => {
        Utils.preventDefault(e);
        if (this.state.folderName) {
            AppActions.editFolder(this.props.node, {
                name: this.state.folderName,
                parentId: this.props.breadcrumb.length > 1 ? this.props.breadcrumb[this.props.breadcrumb.length - 2].id : null,
            });
        }
    };

    render() {
        $(() => {
            $('[data-toggle="tooltip"]').tooltip();
        });
        const navigationLoading = !AccountStore.getNavigation();
        const isAdmin = AccountStore.getUser().isAdmin;
        return (
            <div>
                <div className="card card--report">
                    {isAdmin ? (
                        <ul className="nav justify-content-end">
                            <li
                              onClick={this.showCreateFolder}
                              className={`nav-item nav-link${navigationLoading ? ' disabled' : ''}`}
                              data-toggle="tooltip" data-placement="top"
                              title="Create Folder"
                            >
                                <i className="fas fa-folder"/>
                            </li>
                            <li
                              onClick={this.showCreateReport}
                              className={`nav-item nav-link${navigationLoading ? ' disabled' : ''}`}
                              data-toggle="modal" data-target="#createReportModal"
                            >
                                <i
                                  className="fas fa-file" data-toggle="tooltip" data-placement="top"
                                  title="Create Report"
                                />
                            </li>
                            <li onClick={this.showEditFolder} className={`nav-item nav-link${navigationLoading ? ' disabled' : ''}`}>
                                <i
                                  className="fas fa-edit" data-toggle="tooltip" data-placement="top"
                                  title="Edit Folder"
                                />
                            </li>
                            <li onClick={this.showFolderPermissions} className={`nav-item nav-link${navigationLoading ? ' disabled' : ''}`}>
                                <i
                                  className="fas fa-lock" data-toggle="tooltip" data-placement="top"
                                  title="Permissions"
                                />
                            </li>
                            <li onClick={this.showFilters} className={`nav-item nav-link${navigationLoading ? ' disabled' : ''}`}>
                                <i
                                  className="fas fa-filter" data-toggle="tooltip" data-placement="top"
                                  title="Filters"
                                />
                            </li>
                            <li
                              onClick={this.showDeleteFolder}
                              className={`nav-item nav-link${navigationLoading ? ' disabled' : ''}`}
                            >
                                <i
                                  className="fas fa-trash" data-toggle="tooltip" data-placement="top"
                                  title="Delete"
                                />
                            </li>
                        </ul>
                    ) : null}
                    <FolderTable id={this.props.node}/>

                    <div
                      id="filters-modal"
                      className="modal fade bd-example-modal-lg" tabIndex="-1"
                      role="dialog" aria-labelledby="filtersModalLabel" aria-hidden="true"
                    >
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="filtersModalLabel">Filters</h5>
                                    <button
                                      type="button" className="close" data-dismiss="modal"
                                      aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    {this.state.showFolderFilters && (
                                    <ManageFolderFilters onSave={this.onFolderFiltersSave} id={this.props.node}/>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                      id="permissions-modal"
                      className="modal fade bd-example-modal-lg" tabIndex="-1"
                      role="dialog" aria-labelledby="createReportModalLabel" aria-hidden="true"
                    >
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="createReportModalLabel">User Groups</h5>
                                    <button
                                      type="button" className="close" data-dismiss="modal"
                                      aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    {this.state.showFolderPermissions && (
                                    <ManageFolderPermissions onSave={this.onFolderPermissionsSave} id={this.props.node}/>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                      id="create-folder-modal"
                      className="modal fade bd-example-modal-lg" tabIndex="-1"
                      role="dialog" aria-labelledby="createFolderModalLabel" aria-hidden="true"
                    >
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="createFolderModalLabel">{`${this.state.editFolder ? 'Edit' : 'Create'} Folder`}</h5>
                                    <button
                                      type="button" className="close" data-dismiss="modal"
                                      aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <form onSubmit={this.state.editFolder ? this.editFolder : this.createFolder}>
                                        <label>Folder Name</label>
                                        <input
                                          onChange={e => this.setState({ folderName: Utils.safeParseEventValue(e) })} value={this.state.folderName} type="text"
                                          className="form-control"
                                        />
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn--secondary-outline" data-dismiss="modal">CANCEL</button>
                                    <button
                                      onClick={this.state.editFolder ? this.editFolder : this.createFolder} disabled={!this.state.folderName} type="button"
                                      className="btn--primary-outline"
                                    >
                                        {AccountStore.isSaving ? 'Saving' : 'Save'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                      id="create-report-modal"
                      className="modal fade bd-example-modal-lg" tabIndex="-1"
                      role="dialog" aria-labelledby="createReportModalLabel" aria-hidden="true"
                    >
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="createReportModalLabel">Create Report</h5>
                                    <button
                                      type="button" className="close" data-dismiss="modal"
                                      aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <form onSubmit={this.state.reportName && this.createFolder}>
                                        <label>Report Name</label>
                                        <input
                                          onChange={e => this.setState({ reportName: Utils.safeParseEventValue(e) })} value={this.state.reportName} type="text"
                                          className="form-control"
                                        />
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn--secondary-outline" data-dismiss="modal">CANCEL</button>
                                    <button
                                      onClick={this.createReport} disabled={!this.state.reportName} type="button"
                                      className="btn--primary-outline"
                                    >
                                        {AccountStore.isSaving ? 'Saving' : 'Save'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                      className="modal fade bd-example-modal-lg" id="delete-folder-modal" tabIndex="-1"
                      role="dialog" aria-labelledby="deleteGroupModalLabel" aria-hidden="true"
                    >
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="deleteGroupModalLabel">REMOVE FOLDER</h5>
                                    <button
                                      type="button" className="close" data-dismiss="modal"
                                      aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p>
                                        Are you sure you wish to delete this folder?
                                    </p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn--secondary-outline" data-dismiss="modal">CANCEL</button>
                                    <button
                                      onClick={this.deleteFolder} disabled={AccountStore.isSaving} type="button"
                                      className="btn--primary-outline"
                                    >
                                        Confirm
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

DashboardPage.propTypes = {
    breadcrumb: propTypes.array,
};

export default hot(module)(withFolderNavigation(DashboardPage));
