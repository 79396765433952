/**
 * Created by kylejohnson on 21/08/2016.
 */
import React from 'react';
import AccountStore from '../../../common/stores/account-store';
import ReportStore from '../../../common/stores/report-store';
import ReportTable from '../ReportTable';
import ManageReportPermissions from '../ManageReportPermissions';
import ManageReportFilters from '../ManageReportFilters';
import withFolderNavigation from '../../../common/providers/withFolderNavigation';
import { openModal } from '../../project/modals';
import FolderSelect from '../FolderSelect';

const ReportPage = class extends React.Component {
    displayName = 'ReportPage';

    state = {
        reportLoading: ReportStore.isLoading,
    };

    static contextTypes = {
        router: propTypes.object.isRequired,
    };

    componentDidMount() {
        $('#permissions-modal').on('hidden.bs.modal', () => {
            this.setState({ showReportPermissions: false });
        });
        $('#filters-modal').on('hidden.bs.modal', () => {
            this.setState({ showReportFilters: false });
        });
        ES6Component(this);
        this.listenTo(AccountStore, 'change', () => this.forceUpdate());
        this.listenTo(AccountStore, 'report-updated', (id) => {
            $('#edit-report-modal').modal('hide');
            this.setState({ reportName: null });
        });
        this.listenTo(AccountStore, 'deleted', () => {
            const { context: { router: { history, route: { match: { params: { redirect } } } } } } = this;
            $('#delete-report-modal').modal('hide');

            if (this.props.breadcrumb && this.props.breadcrumb.length >= 2) {
                history.replace(`/folders/${this.props.breadcrumb[this.props.breadcrumb.length - 2].id}`);
            } else {
                history.replace('/dashboard');
            }
        });
        this.setState({ reportLoading: ReportStore.isLoading });
        this.listenTo(ReportStore, 'change', () => {
            this.setState({ reportLoading: ReportStore.isLoading });
        });
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (nextProps.id !== this.props.id) {
            this.state.showReportPermissions = false;
        }
    }

    onReportPermissionsSave = () => {
        $('#permissions-modal').modal('hide');
        this.setState({ showReportPermissions: false });
    }

    showReportPermissions = () => {
        this.setState({ showReportPermissions: true }, () => {
            $('#permissions-modal').modal('show');
        });
    };

    onReportFiltersSave = () => {
        $('#filters-modal').modal('hide');
        this.setState({ showReportFilters: false });
    }

    showFilters = () => {
        this.setState({ showReportFilters: true }, () => {
            $('#filters-modal').modal('show');
        });
    };

    showEditReport = () => {
        this.setState({ reportName: _.last(this.props.breadcrumb).name }, () => {
            $('#edit-report-modal').modal('show');
        });
    }

    editReport = (e) => {
        Utils.preventDefault(e);
        if (this.state.reportName) {
            AppActions.editReport(this.props.node, {
                name: this.state.reportName,
                folderId: this.props.breadcrumb[this.props.breadcrumb.length - 2].id,
            });
        }
    };

    showDeleteReport = () => {
        $('#delete-report-modal').modal('show');
    };

    deleteReport = (e) => {
        Utils.preventDefault(e);
        AppActions.deleteReport(this.props.node);
    };


    publish = () => {
        openModal({
            className: 'folder-select-modal',
            header: (
                <React.Fragment>
                    <h5 className="modal-title" id="filtersModalLabel">Select reports to publish</h5>
                    <button
                        onClick={()=>closeModal()}
                        type="button" className="close" data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </React.Fragment>
            ),
            body:
                <div>
                    <p>Choose the folders you would like the report to be copied to</p>
                    <FolderSelect reportId={this.props.node}/>
                </div>,
        });
    }

    render() {
        const { reportLoading } = this.state;
        $(() => {
            $('[data-toggle="tooltip"]').tooltip();
        });
        const isAdmin = AccountStore.getUser().isAdmin;
        return (
            <div>

                <div className="card card--report">
                    {isAdmin ? (
                        <ul className="nav justify-content-end">
                            <li onClick={this.showEditReport} className={`nav-item nav-link${reportLoading ? ' disabled' : ''}`}>
                                <i
                                  className="fas fa-edit" data-toggle="tooltip" data-placement="top"
                                  title="Edit Report"
                                />
                            </li>
                            <li onClick={this.showReportPermissions} className={`nav-item nav-link${reportLoading ? ' disabled' : ''}`}>
                                <i
                                  className="fas fa-lock" data-toggle="tooltip" data-placement="top"
                                  title="Permissions"
                                />
                            </li>
                            <li onClick={this.showFilters} className={`nav-item nav-link${reportLoading ? ' disabled' : ''}`}>
                                <i
                                  className="fas fa-filter" data-toggle="tooltip" data-placement="top"
                                  title="Filters"
                                />
                            </li>
                            <li onClick={this.publish} className={`nav-item nav-link${reportLoading ? ' disabled' : ''}`}>
                                <i
                                  className="fas fa-upload" data-toggle="tooltip" data-placement="top"
                                  title="Publish Report"
                                />
                            </li>
                            <li
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.nativeEvent.stopImmediatePropagation();
                                    e.stopPropagation();
                                    e.bubbles = false;
                                    Utils.tryDownload(this.props.node, this.state.reportName);
                            }}
                              className={`nav-item nav-link${reportLoading ? ' disabled' : ''}`}
                            >
                                <i
                                  className="fas fa-download" data-toggle="tooltip" data-placement="top"
                                  title="Download Report"
                                />
                            </li>
                            <li onClick={this.showDeleteReport} className={`nav-item nav-link${reportLoading ? ' disabled' : ''}`}>
                                <i
                                  className="fas fa-trash" data-toggle="tooltip" data-placement="top"
                                  title="Delete"
                                />
                            </li>
                        </ul>
                    ) : (
                        <ul className="nav justify-content-end">
                            <li
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.nativeEvent.stopImmediatePropagation();
                                    e.stopPropagation();
                                    e.bubbles = false;
                                    Utils.tryDownload(this.props.node, this.state.reportName);
                                }}
                                className={`nav-item nav-link${reportLoading ? ' disabled' : ''}`}
                            >
                                <i
                                    className="fas fa-download" data-toggle="tooltip" data-placement="top"
                                    title="Download Report"
                                />
                            </li>
                        </ul>
                    )}
                    <ReportTable id={this.props.node}/>

                    <div
                      id="filters-modal"
                      className="modal fade bd-example-modal-lg" tabIndex="-1"
                      role="dialog" aria-labelledby="filtersModalLabel" aria-hidden="true"
                    >
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="filtersModalLabel">Filters</h5>
                                    <button
                                      type="button" className="close" data-dismiss="modal"
                                      aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    {this.state.showReportFilters && (
                                    <ManageReportFilters onSave={this.onReportFiltersSave} id={this.props.node}/>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                      id="permissions-modal"
                      className="modal fade bd-example-modal-lg" tabIndex="-1"
                      role="dialog" aria-labelledby="reportPermissionsModalLabel" aria-hidden="true"
                    >
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="reportPermissionsModalLabel">User Groups</h5>
                                    <button
                                      type="button" className="close" data-dismiss="modal"
                                      aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    {this.state.showReportPermissions && (
                                        <ManageReportPermissions onSave={this.onReportPermissionsSave} id={this.props.node}/>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                      id="edit-report-modal"
                      className="modal fade bd-example-modal-lg" tabIndex="-1"
                      role="dialog" aria-labelledby="editReportModalLabel" aria-hidden="true"
                    >
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="editReportModalLabel">Edit Report</h5>
                                    <button
                                      type="button" className="close" data-dismiss="modal"
                                      aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <form onSubmit={this.editReport}>
                                        <label>Report Name</label>
                                        <input
                                          onChange={e => this.setState({ reportName: Utils.safeParseEventValue(e) })} value={this.state.reportName} type="text"
                                          className="form-control"
                                        />
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn--secondary-outline" data-dismiss="modal">CANCEL</button>
                                    <button
                                      onClick={this.editReport} disabled={!this.state.reportName} type="button"
                                      className="btn--primary-outline"
                                    >
                                        {AccountStore.isSaving ? 'Saving' : 'Save'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                      className="modal fade bd-example-modal-lg" id="delete-report-modal" tabIndex="-1"
                      role="dialog" aria-labelledby="deleteReportModalLabel" aria-hidden="true"
                    >
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="deleteGroupModalLabel">REMOVE REPORT</h5>
                                    <button
                                      type="button" className="close" data-dismiss="modal"
                                      aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p>
                                        Are you sure you wish to delete this report?
                                    </p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn--secondary-outline" data-dismiss="modal">CANCEL</button>
                                    <button
                                      onClick={this.deleteReport} disabled={AccountStore.isSaving} type="button"
                                      className="btn--primary-outline"
                                    >
                                        Confirm
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

ReportPage.propTypes = {
    breadcrumb: propTypes.array,
};

export default hot(module)(withFolderNavigation(ReportPage));
