const Constants = window.Constants = {
    loginRedirect: '/dashboard',
    logoutRedirect: '/',
    events: {
        'LOGIN': { 'event': 'User login', 'category': 'User' },
        'REGISTER': { 'event': 'User register', 'category': 'User' },
    },
    pages: {
        NOT_FOUND: 'Not Found',
        HOME_PAGE: 'Home',
    },
    strings: {},

    simulate: {},
};

export default Constants;