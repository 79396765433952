const AppActions = Object.assign({}, require('./base/_app-actions'), {
    setNavigation(id) {
        Dispatcher.handleViewAction({
            actionType: Actions.SET_NAVIGATION,
            id,
        });
    },
    getFolders(defaultFolder) {
        Dispatcher.handleViewAction({
            actionType: Actions.GET_FOLDERS,
            defaultFolder,
        });
    },
    getUsers() {
        Dispatcher.handleViewAction({
            actionType: Actions.GET_USERS,
        });
    },
    getUser(id) {
        Dispatcher.handleViewAction({
            actionType: Actions.GET_USER,
            id,
        });
    },
    getCase(id, reportId) {
        Dispatcher.handleViewAction({
            actionType: Actions.GET_CASE,
            id,
            reportId,
        });
    },
    getFolderPermissions(id) {
        Dispatcher.handleViewAction({
            actionType: Actions.GET_FOLDER_PERMISSIONS,
            id,
        });
    },
    setFolderPermissions(id, groups) {
        Dispatcher.handleViewAction({
            actionType: Actions.SET_FOLDER_PERMISSIONS,
            id,
            groups,
        });
    },
    getReport(id) {
        Dispatcher.handleViewAction({
            actionType: Actions.GET_REPORT,
            id,
        });
    },
    getReportContents(id) {
        Dispatcher.handleViewAction({
            actionType: Actions.GET_REPORT_CONTENTS,
            id,
        });
    },
    getFields() {
        Dispatcher.handleViewAction({
            actionType: Actions.GET_FIELDS,
        });
    },
    deleteUser(id) {
        Dispatcher.handleViewAction({
            actionType: Actions.DELETE_USER,
            id,
        });
    },
    updateUser(data) {
        Dispatcher.handleViewAction({
            actionType: Actions.UPDATE_USER,
            data,
        });
    },
    updateUserPassword(data) {
        Dispatcher.handleViewAction({
            actionType: Actions.UPDATE_USER_PASSWORD,
            data,
        });
    },
    createUser(data) {
        Dispatcher.handleViewAction({
            actionType: Actions.CREATE_USER,
            data,
        });
    },
    getUserGroups() {
        Dispatcher.handleViewAction({
            actionType: Actions.GET_USER_GROUPS,
        });
    },
    updateUserGroup(data) {
        Dispatcher.handleViewAction({
            actionType: Actions.UPDATE_USER_GROUP,
            data,
        });
    },
    createUserGroup(data) {
        Dispatcher.handleViewAction({
            actionType: Actions.CREATE_USER_GROUP,
            data,
        });
    },
    createFolder(data) {
        Dispatcher.handleViewAction({
            actionType: Actions.CREATE_FOLDER,
            data,
        });
    },
    deleteFolder(id) {
        Dispatcher.handleViewAction({
            actionType: Actions.DELETE_FOLDER,
            id,
        });
    },
    deleteReport(id) {
        Dispatcher.handleViewAction({
            actionType: Actions.DELETE_REPORT,
            id,
        });
    },
    createReport(data) {
        Dispatcher.handleViewAction({
            actionType: Actions.CREATE_REPORT,
            data,
        });
    },
    deleteUserGroup(id) {
        Dispatcher.handleViewAction({
            actionType: Actions.DELETE_USER_GROUP,
            id,
        });
    },
    getReportPermissions(id) {
        Dispatcher.handleViewAction({
            actionType: Actions.GET_REPORT_PERMISSIONS,
            id,
        });
    },
    setReportPermissions(id, groups) {
        Dispatcher.handleViewAction({
            actionType: Actions.SET_REPORT_PERMISSIONS,
            id,
            groups,
        });
    },
    getReportContents(id) {
        Dispatcher.handleViewAction({
            actionType: Actions.GET_REPORT_CONTENTS,
            id,
        });
    },
    getFolderFilters(id) {
        Dispatcher.handleViewAction({
            actionType: Actions.GET_FOLDER_FILTERS,
            id,
        });
    },
    setFolderFilters(id, filters) {
        Dispatcher.handleViewAction({
            actionType: Actions.SET_FOLDER_FILTERS,
            id,
            filters,
        });
    },
    getReportFilters(id) {
        Dispatcher.handleViewAction({
            actionType: Actions.GET_REPORT_FILTERS,
            id,
        });
    },
    setReportFilters(id, filters) {
        Dispatcher.handleViewAction({
            actionType: Actions.SET_REPORT_FILTERS,
            id,
            filters,
        });
    },
    editFolder(id, details) {
        Dispatcher.handleViewAction({
            actionType: Actions.EDIT_FOLDER,
            id,
            details,
        });
    },
    editReport(id, details) {
        Dispatcher.handleViewAction({
            actionType: Actions.EDIT_REPORT,
            id,
            details,
        });
    },
    getWhitelabel() {
        Dispatcher.handleViewAction({
            actionType: Actions.GET_WHITELABEL,
        });
    },
});

export default AppActions;
window.AppActions = AppActions;
