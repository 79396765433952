import WhitelabelStore from '../stores/whitelabel-store';

export default (WrappedComponent) => {
    class HOC extends React.Component {
        static displayName = 'withWhitelabel';

        constructor(props) {
            super(props);
            ES6Component(this);
            this.listenTo(WhitelabelStore, 'change', () => {
                this.setState({
                    whitelabel: WhitelabelStore.getWhitelabel(),
                    whitelabelLoading: WhitelabelStore.isLoading,
                    whitelabelSaving: WhitelabelStore.isSaving,
                });
            });
            this.state = {
                whitelabel: WhitelabelStore.getWhitelabel(),
                whitelabelLoading: WhitelabelStore.isLoading,
                whitelabelSaving: WhitelabelStore.isSaving,
            };
        }

        render() {
            const { whitelabel, whitelabelLoading, whitelabelSaving } = this.state;
            return (
                <WrappedComponent
                  ref={c => this.wrappedComponent = c}
                  whitelabel={whitelabel}
                  whitelabelLoading={whitelabelLoading}
                  whitelabelSaving={whitelabelSaving}
                  {...this.props}
                />
            );
        }
    }

    return HOC;
};
