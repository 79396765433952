import React, { Component } from 'react';

import UserGroupSelect from './UserGroupSelect';
import withFolderPermissions from '../../common/providers/withFolderPermissions';

const DocumentTable = class extends Component {
    static displayName = 'DocumentTable';

    constructor(props) {
        super(props);
        AppActions.getFolderPermissions(props.id);
        AppActions.getUserGroups();
    }

    componentWillUpdate(nextProps) {
        if (nextProps.id !== this.props.id) {
            AppActions.getFolderPermissions(nextProps.id);
        }
    }

    onSave = (group) => {
        this.props.onSave && this.props.onSave();
    }


    render() {
        const { permissions, permissionsLoading, permissionsSaving, setFolderPermissions, saveFolderPermissions } = this.props;
        return (
            <div>
                {permissionsLoading ? (
                    <Loader/>
                ) : (
                    <UserGroupSelect
                      value={permissions}
                      onChange={setFolderPermissions}
                    />
                )}
                <div className="modal-footer">
                    <button type="button" className="btn--secondary-outline" data-dismiss="modal">CANCEL</button>
                    <button
                      onClick={saveFolderPermissions} disabled={permissionsSaving} type="button"
                      className="btn--primary-outline"
                    >
                        {permissionsSaving ? 'Saving' : 'Save'}
                    </button>
                </div>
            </div>
        );
    }
};

DocumentTable.propTypes = {};

module.exports = withFolderPermissions(DocumentTable);
