import BaseStore from './base/_store';
import data from './base/_data';

const store = Object.assign({}, BaseStore, {
    id: 'account',
    getCase(id) {
        return store.model && store.model.caInCaseId === parseInt(id) && store.model;
    },

});

const controller = {
    getCase: (id, reportId) => {
        store.loading();
        data.get(`${Project.api}cases/${id}?reportId=${reportId}`).then((res) => {
            store.model = res;
            store.loaded();
        }).catch(res => API.ajaxHandler(store, res));
    },

};

store.dispatcherIndex = Dispatcher.register(store, (payload) => {
    const action = payload.action; // this is our action from handleViewAction

    switch (action.actionType) {
        case Actions.GET_CASE:
            controller.getCase(action.id, action.reportId);
            break;
        default:
    }
});


controller.store = store;
module.exports = controller.store;
