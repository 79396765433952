import AccountStore from '../stores/account-store';

export default (WrappedComponent) => {
    class HOC extends React.Component {
        static displayName = 'withFolderNavigation';

        constructor(props) {
            super(props);
            ES6Component(this);
            this.listenTo(AccountStore, 'navigation', () => {
                this.setState({
                    tree: AccountStore.getNavigation(),
                    expandedItems: AccountStore.getExpandedItems(),
                    breadcrumb: AccountStore.getBreadcrumb(),
                });
            });
            this.state = {
                tree: AccountStore.getNavigation(),
                expandedItems: AccountStore.getExpandedItems(),
                breadcrumb: AccountStore.getBreadcrumb(),
            };
        }

        navigate = (node) => {
            AppActions.setNavigation(node);
        };

        render() {
            const { navigate } = this;
            const { tree, expandedItems, breadcrumb } = this.state;
            return (
                <WrappedComponent
                  ref="wrappedComponent"
                  tree={tree}
                  navigate={navigate}
                  breadcrumb={breadcrumb}
                  expandedItems={expandedItems}
                  {...this.props}
                />
            );
        }
    }

    return HOC;
};