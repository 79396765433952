import React, { Component } from 'react';
import cn from 'classnames';

const TheComponent = class extends Component {
    static displayName = 'TreeNode';

    static propTypes = {
        children: propTypes.node,
        expandedItems: propTypes.object,
        level: propTypes.number,
    };

    static defaultProps = {
        expandedItems: {},
        level: 0,
    };

    componentDidUpdate(prevProps) {
        const isExpanded = !!this.props.expandedItems[this.props.id];
        const wasExpended = !!this.props.expandedItems[prevProps.id];
        if (isExpanded !== wasExpended) {
            if (isExpanded) {
                // do expanding animation
            } else {
                // do collapsing animation
            }
        }
    }

    render() {
        const { props: { children, expandedItems, name, id, parentId, onClick, level } } = this;
        const expanded = !!expandedItems[id];
        return (
            <ul className={cn({
                'list-unstyled folder__nav nav flex-column ml-0 mr-0': true,
                active: expanded,
            })}
            >

                <li className={cn({
                    'nav-item': true,
                    active: expanded,
                })}
                >
                    <span
                      role="button"
                      onClick={() => onClick(expanded ? parentId : id)}
                      data-toggle="collapse"
                      aria-expanded={expanded}
                      className={cn({
                          'nav-link': true,
                          active: expanded,
                          'dropdown-toggle': true,
                      })}
                    >
                        <i className="fas fa-folder mr-3" />
                        {name}
                    </span>
                    {
                        children && children.map(node => (
                            <TreeNode
                              onClick={onClick}
                              key={node.id}
                              parentId={id}
                              expandedItems={expandedItems}
                              level={level + 1}
                              {...node}
                            />
                        ))
                    }
                </li>
            </ul>
        );
    }
};

TheComponent.propTypes = {};

module.exports = TheComponent;