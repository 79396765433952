import React from 'react';
import { Route, Switch } from 'react-router-dom';
import App from './App';
// App Wrapper
// eslint-disable-next-line
const withSidebar = Page => props => (
    <div className="container-fluid">
        <div className="row">
            <Sidebar {...props.match.params} key="sidebar"/>
            <div className="main col-md-12 pl-0 pr-0">
                <Page {...props.match.params} {...Utils.fromParam()}/>
            </div>
        </div>
    </div>
);

withSidebar.displayName = 'withSidebar';

export default (
    <App>
        {/* Render one of these routes */}
        <Switch>
            <Route path="/" exact component={require('./components/pages/HomePage').default}/>
            <Route
              path="/folders/:node" exact render={withSidebar(require('./components/pages/FolderPage').default)}
            />
            <Route
              path="/reports/:node/cases/:id" exact render={withSidebar(require('./components/pages/CasePage').default)}
            />
            <Route
              path="/reports/:node" exact render={withSidebar(require('./components/pages/ReportPage').default)}
            />
            <Route path="/dashboard" exact render={withSidebar(require('./components/pages/FolderPage').default)}/>
            <Route path="/admin/users" exact component={withSidebar(require('./components/pages/ManageUsersPage').default)}/>
            <Route path="/admin/users/create" exact component={withSidebar(require('./components/pages/ManageUserPage').default)}/>
            <Route path="/admin/users/:id" exact component={withSidebar(require('./components/pages/ManageUserPage').default)}/>
            <Route path="/admin/groups" exact component={withSidebar(require('./components/pages/ManageGroupsPage').default)}/>
            <Route path="/admin/groups/create" exact component={withSidebar(require('./components/pages/ManageGroupPage').default)}/>
            <Route path="/admin/groups/:id" exact component={withSidebar(require('./components/pages/ManageGroupPage').default)}/>
            <Route path="/settings" exact component={withSidebar(require('./components/pages/SettingsPage').default)}/>
            <Route path="/faq" exact component={withSidebar(require('./components/pages/SettingsPage').default)}/>
            <Route component={require('./components/pages/NotFoundPage').default}/>
        </Switch>
    </App>
);
