/**
 * Created by kylejohnson on 21/08/2016.
 */
import React from 'react';
import withCase from '../../../common/providers/withCase';
import DocumentsTable from '../DocumentTable';

const CasePage = class extends React.Component {
    displayName = 'CasePage';

    componentWillMount() {
        AppActions.getCase(this.props.id, this.props.node);
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (nextProps.id !== this.props.id && nextProps.id) {
            AppActions.getCase(nextProps.id, nextProps.node);
        }
    }

    formatDate = date => moment(date).format('Do MMM YYYY HH:MMa')

    render() {
        $(() => {
            $('[data-toggle="tooltip"]').tooltip();
        });
        const { formatDate, props: { caseDetails, caseLoading } } = this;
        return (
            <div className="mt-5">
                {caseDetails ? (
                    <div>
                        <div className="col-sm-9 mt-5 pl-0">
                            <ul className="nav nav-pills nav-pills--report nav-fill" id="pills-tab" role="tablist">
                                <li className="nav-item">
                                    <a
                                      className="nav-link active" id="pills-claims-tab" data-toggle="pill"
                                      href="#pills-claims" role="tab" aria-controls="pills-claims"
                                      aria-selected="true"
                                    >
                                        Claims Details
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                      className="nav-link" id="pills-documents-tab" data-toggle="pill"
                                      href="#pills-documents" role="tab" aria-controls="pills-documents"
                                      aria-selected="false"
                                    >
                                        Correspondence
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                      className="nav-link" id="pills-correspondence-tab" data-toggle="pill"
                                      href="#pills-correspondence" role="tab" aria-controls="pills-correspondence"
                                      aria-selected="false"
                                    >
                                        Payments
                                    </a>
                                </li>
                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                                <div
                                  className="tab-pane fade show active" id="pills-claims" role="tabpanel"
                                  aria-labelledby="pills-claims-tab"
                                >
                                    <div className="card card--report-detail">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label className="col-sm-6 col-form-label">Claim number</label>
                                                        <div className="col-sm-6">
                                                            <input
                                                              value={caseDetails.claimNumber}
                                                              type="text" readOnly className="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-6 col-form-label">Policy number</label>
                                                        <div className="col-sm-6">
                                                            <input
                                                              value={caseDetails.policyNumber}
                                                              type="text" readOnly className="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-6 col-form-label">Insured surname</label>
                                                        <div className="col-sm-6">
                                                            <input
                                                              value={caseDetails.insuredSurname}
                                                              type="text" readOnly className="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-6 col-form-label">Date of loss</label>
                                                        <div className="col-sm-6">
                                                            <input
                                                              value={formatDate(caseDetails.lossDate)}
                                                              type="text" readOnly className="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-6 col-form-label">Loss type</label>
                                                        <div className="col-sm-6">
                                                            <input
                                                              value={caseDetails.lossType}
                                                              type="text" readOnly className="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-6 col-form-label">Adjustor</label>
                                                        <div className="col-sm-6">
                                                            <input
                                                              value={caseDetails.adjuster}
                                                              type="text" readOnly className="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label className="col-sm-6 col-form-label">Risk address</label>
                                                        <div className="col-sm-6">
                                                            <input
                                                              value={caseDetails.lossAd1}
                                                              type="text" readOnly className="form-control"
                                                            />
                                                            {caseDetails.lossAd2 && (
                                                                <input
                                                                  value={caseDetails.lossAd2}
                                                                  type="text" readOnly className="mt-1 form-control"
                                                                />
                                                            )}
                                                            {caseDetails.lossAd3 && (
                                                                <input
                                                                  value={caseDetails.lossAd3}
                                                                  type="text" readOnly className="mt-1 form-control"
                                                                />
                                                            )}
                                                            {caseDetails.lossAd4 && (
                                                                <input
                                                                  value={caseDetails.lossAd4}
                                                                  type="text" readOnly className="mt-1 form-control"
                                                                />
                                                            )}
                                                            {caseDetails.lossAd5 && (
                                                                <input
                                                                  value={caseDetails.lossAd5}
                                                                  type="text" readOnly className="mt-1 form-control"
                                                                />
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label className="col-sm-6 col-form-label">Postcode</label>
                                                        <div className="col-sm-6">
                                                            <input
                                                              value={caseDetails.lossPostcode}
                                                              type="text" readOnly className="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-6 col-form-label">Policy Start Date</label>
                                                        <div className="col-sm-6">
                                                            <input
                                                              value={formatDate(caseDetails.inceptionDate)}
                                                              type="text" readOnly className="form-control"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label className="col-sm-6 col-form-label">Claim Status</label>
                                                        <div className="col-sm-6">
                                                            <input
                                                              value={caseDetails.caseStatus}
                                                              type="text" readOnly className="form-control"
                                                            />
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                  className="tab-pane fade" id="pills-documents" role="tabpanel"
                                  aria-labelledby="pills-documents-tab"
                                >
                                    <div className="card card--report-detail">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label className="col-sm-6 col-form-label">Claim received</label>
                                                        <div className="col-sm-6">
                                                            <input
                                                              value={formatDate(caseDetails.prelimDate)}
                                                              type="text" readOnly className="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-6 col-form-label">Date of contact</label>
                                                        <div className="col-sm-6">
                                                            <input
                                                              value={formatDate(caseDetails.lastContactInsurer)}
                                                              type="text" readOnly className="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-6 col-form-label">Last insured update</label>
                                                        <div className="col-sm-6">
                                                            <input
                                                              value={formatDate(caseDetails.lastContactInsured)}
                                                              type="text" readOnly className="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-6 col-form-label">Last insurer update</label>
                                                        <div className="col-sm-6">
                                                            <input
                                                              value={formatDate(caseDetails.lossDate)}
                                                              type="text" readOnly className="form-control"
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label className="col-sm-6 col-form-label">Date of loss</label>
                                                        <div className="col-sm-6">
                                                            <input
                                                              value={formatDate(caseDetails.lossDate)}
                                                              type="text" readOnly className="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-6 col-form-label">Appointment</label>
                                                        <div className="col-sm-6">
                                                            <input
                                                              value={formatDate(caseDetails.appointment)}
                                                              type="text" readOnly className="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-6 col-form-label">
                                                            Prelim
                                                        </label>
                                                        <div className="col-sm-6">
                                                            <input
                                                              value={formatDate(caseDetails.prelimDate)}
                                                              type="text" readOnly className="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-6 col-form-label">
                                                            Final
                                                        </label>
                                                        <div className="col-sm-6">
                                                            <input
                                                              value={formatDate(caseDetails.finalDate)}
                                                              type="text" readOnly className="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <DocumentsTable documents={caseDetails.documents} reportId={this.props.node} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                  className="tab-pane fade" id="pills-correspondence" role="tabpanel"
                                  aria-labelledby="pills-correspondence-tab"
                                >
                                    <div className="card card--report-detail">
                                        <div className="card-body">

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label">Reserve</label>
                                                        <div className="col-sm-3">
                                                            <input
                                                              value={caseDetails.totalReserve}
                                                              type="text" readOnly className="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label">o/s Reserve</label>
                                                        <div className="col-sm-3">
                                                            <input
                                                              value={caseDetails.outstandingReserve}
                                                              type="text" readOnly className="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label className="col-sm-6 col-form-label">Claim</label>
                                                        <div className="col-sm-6">
                                                            <input
                                                              value={caseDetails.claimThisMonth}
                                                              type="text" readOnly className="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-6 col-form-label">Adjuster Fee</label>
                                                        <div className="col-sm-6">
                                                            <input
                                                              value={caseDetails.feeThisMonth}
                                                              type="text" readOnly className="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-6 col-form-label">Handling Fee</label>
                                                        <div className="col-sm-6">
                                                            <input
                                                              value={caseDetails.handling && caseDetails.handling.toFixed(2)}
                                                              type="text" readOnly className="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-6 col-form-label">Total</label>
                                                        <div className="col-sm-6">
                                                            <input
                                                              value={caseDetails.totalIncurredIncludingFees && caseDetails.totalIncurredIncludingFees.toFixed(2)}
                                                              type="text" readOnly className="form-control"
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label className="col-sm-6 col-form-label">Claim (payments to date)</label>
                                                        <div className="col-sm-6">
                                                            <input
                                                              value={caseDetails.claimPrevious && caseDetails.claimPrevious.toFixed(2)}
                                                              type="text" readOnly className="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-6 col-form-label">Adjuster fee (payments to date)</label>
                                                        <div className="col-sm-6">
                                                            <input
                                                              value={caseDetails.adjusterPrev && caseDetails.adjusterPrev.toFixed(2)}
                                                              type="text" readOnly className="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="container-fluid text-center">
                        {caseLoading ? <Loader/> : (
                            <div className="row pb-3">
                                <div className="col-sm-12">
                                    <img alt="Empty Folder" className="mb-5" src="/images/empty_folder_Icon_.svg" />
                                    <h1 className="h1--secondary">Could not load case</h1>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
};

export default hot(module)(withCase(CasePage));
