import React, { Component } from 'react';

import {
    DataTypeProvider,
    PagingState,
    IntegratedPaging,
    SortingState,
    IntegratedSorting,
    FilteringState,
    IntegratedFiltering,
    SelectionState,
    IntegratedSelection,
} from '@devexpress/dx-react-grid';


import {
    Grid,
    DragDropProvider,
    PagingPanel,
    Table,
    TableFilterRow,
    TableHeaderRow,
    TableSelection,
    TableColumnReordering,
    TableColumnResizing,

} from '@devexpress/dx-react-grid-material-ui';

import withFolderNavigation from '../../common/providers/withFolderNavigation';

const CustomFilter = props => (
    ['type', 'download'].includes(props.column.name) ? <TableFilterRow.Cell/> : <TableFilterRow.Cell {...props} className="filter"/>
);

const FolderTable = class extends Component {
    static displayName = 'FolderTable';

    static contextTypes = {
        router: propTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        const columnWidths = [
            { columnName: 'type', width: 80 },
            { columnName: 'id', width: 180 },
            { columnName: 'download', width: 120 },
        ];

        let total = 0;
        _.each(columnWidths, c => total += c.width);

        columnWidths.push({ columnName: 'name', width: (document.body.offsetWidth - total) - 380 });

        this.state = {
            columnOrder: ['id', 'type', 'name', 'download'],
            selection: [],
            columnWidths,
        };
    }

    componentWillUpdate(nextProps) {
        if (nextProps.id !== this.props.id) {
            this.state.filters = [];
            this.state.selection = [];
        }
    }

    changeSelection = selection => this.setState({ selection });

    navigate = (id, type) => {
        const { context: { router: { history: { push } } } } = this;
        if (id == null) {
            push('/dashboard');
        } else {
            push(type === 'FOLDER' ? `/folders/${id}` : `/reports/${id}`);
        }
    };

    changeColumnOrder = (newOrder) => {
        this.setState({ columnOrder: newOrder });
    }

    render() {
        const { state: { columnOrder, selection }, props: { breadcrumb, tree } } = this;
        const node = breadcrumb && breadcrumb[breadcrumb.length - 1];
        const children = (node && node.items && _.sortBy(node.items, item => item.name.toLowerCase())) || (tree && tree.map(i => ({ ...i, type: 'FOLDER' })));

        if (!node && !tree) {
            return <div className="text-center"><Loader /></div>;
        }

        return children && children.length ? (

            <Grid
              rows={children}
              columns={[
                  { name: 'type', title: 'Type' },
                  { name: 'id', title: 'ID' },
                  { name: 'name', title: 'Name' },
                  { name: 'download', title: 'Download' },
              ]}
            >

                {/* Sorting */}
                <SortingState
                  defaultSorting={[{ columnName: 'type', direction: 'desc' }]}
                />
                <IntegratedSorting />

                {/* Selection */}
                {/* <SelectionState
                  selection={selection}
                  onSelectionChange={this.changeSelection}
                />
                <IntegratedSelection /> */}

                {/* Custom columns */}
                <DataTypeProvider
                  for={['type']}
                  formatterComponent={({ value }) => <TreeIcon type={value}/>}
                />
                {/* Custom columns */}
                <DataTypeProvider
                  for={['download']}
                  formatterComponent={({ value, row }) => {
                      if (row.type !== 'FILE') return null;
                      return (
                          <a onClick={(e) => { e.preventDefault(); e.nativeEvent.stopImmediatePropagation(); e.stopPropagation(); e.bubbles = false; Utils.tryDownload(row.id, row.name); }}>
                              <i
                                className="fas fa-download" data-toggle="tooltip" data-placement="top"
                                title="Download"
                              />
                          </a>
                      );
                  }}
                />

                {/* Filtering */}
                <FilteringState
                  columnExtensions={[
                      { columnName: 'type', filteringEnabled: false },
                      { columnName: 'download', filteringEnabled: false },
                  ]}
                />
                <IntegratedFiltering />

                <DragDropProvider />
                <PagingState
                  defaultCurrentPage={0}
                  defaultPageSize={1000}
                />
                <IntegratedPaging />

                <Table
                  rowComponent={({ row, ...props }) => (
                      <Table.Row
                        {...props}
                        className="table-row"
                            // eslint-disable-next-line no-alert
                        onClick={() => this.navigate(row.id, row.type)}
                        style={{
                            cursor: 'pointer',
                        }}
                      />
                  )}
                  columnExtensions={[
                      { columnName: 'id', width: 70 },
                      { columnName: 'type', width: 70 },
                      { columnName: 'download', width: 50 },
                  ]}
                />
                <TableColumnReordering
                  order={columnOrder}
                  onOrderChange={this.changeColumnOrder}
                />
                <TableColumnResizing
                  columnWidths={this.state.columnWidths}
                  resizingMode="widget"
                  onColumnWidthsChange={(columnWidths) => {
                      this.setState({ columnWidths });
                  }}
                />
                {/* Paging */}
                <PagingPanel pageSizes={[10, 15, 20, 1000]} />
                {/* The table header */}
                <TableHeaderRow showSortingControls />
                <TableFilterRow
                  cellComponent={CustomFilter}
                  showSortingControls
                />
                {/* <TableSelection showSelectAll /> */}

            </Grid>
        )
            : (
                <div className="container-fluid text-center">
                    <div className="row pb-3">
                        <div className="col-sm-12">
                            <img alt="Empty Folder" className="mb-5" src="/images/empty_folder_Icon_.svg" />
                            <h1 className="h1--secondary">Folder is empty</h1>
                        </div>
                    </div>
                </div>
            );
    }
};

FolderTable.propTypes = {};

module.exports = withFolderNavigation(FolderTable);
