import BaseStore from './base/_store';
import data from './base/_data';

const store = Object.assign({}, BaseStore, {
    id: 'report-filters',
    getReportFilters(id) {
        return store.model && store.reportId === id && store.model;
    },
});

const controller = {
    /**
     * When the filters are sent to the server to store, they are converted to UTC.
     * If they are BST, this adjustment can move the time before midnight, thus changing the date.
     *
     * This function accounts for this (if the date is a BST date). On the way up to the server it sets the time and
     * adjusts the date if necessary. On the way down from the server, it undoes these changes (using the revert param @true)
     * in order to display them correctly in the datepicker again.
     *
     * @param filters
     * @param revert
     * @returns {*}
     */
    adjustFiltersForBST: (filters, revert = false) => {
        function isDate(variable) {
            return (
                variable instanceof Date ||
                (typeof variable === 'string' && !isNaN(Date.parse(variable)))
            );
        }

        let updatedFilters = _.cloneDeep(filters);
        console.log('adjusting filters', filters)

        updatedFilters = updatedFilters.map((filter) => {
            console.log('testieosnf', isDate(filter.value))
            console.log('type', filter.field.fieldType, filter.field.fieldType === "DATE")
            if (filter.field.fieldType === "DATE") {
                let fromDate = new Date(filter.value);
                console.log('fromDate', fromDate)
                console.log('fromDate-')
                const fromDateOffset = fromDate.getTimezoneOffset();
                console.log('offset', fromDateOffset)
                console.log('offset-', fromDateOffset)

                // Adjust time to 00:00 UTC for from date, if currently in BST, on the way up to the server.
                if (fromDateOffset !== 0 && ! revert) {
                    console.log('yes, I change');
                    fromDate.setHours(1,0,0);
                    filter.value = fromDate;
                }
                console.log('maybe?', fromDate, filter.value, fromDateOffset, revert)

                // Adjust time to 23:59:59 UTC, if currently BST, on the way up to the server.
                if (filter.valueTo) {
                    let toDate = new Date(filter.valueTo);
                    const toDateOffset = toDate.getTimezoneOffset();

                    if (toDateOffset !== 0) {
                        // Adjust the date to compensate for the rollover from 1AM to before midnight, when adjusting to UTC.
                        let adjustment = 1;

                        if (revert) {
                            // Undo this change when coming back down from the server.
                            adjustment = adjustment * -1;
                        }
                        toDate.setDate(toDate.getDate() + adjustment);

                        if (! revert) {
                            toDate.setHours(0,59,59);
                        }
                    }
                    filter.valueTo = toDate;
                }
            }
            return filter;
        });

        return updatedFilters;
    },


    getReportFilters: (id) => {
        store.loading();
        data.get(`${Project.api}reports/${id}/filters`).then((res) => {
            store.model = controller.adjustFiltersForBST(res.data, true);
            store.reportId = id;
            store.loaded();
        }).catch(res => API.ajaxHandler(store, res));
    },

    setReportFilters: (id, filters) => {
        store.saving();
        data.put(`${Project.api}reports/${id}/filters`, { data: controller.adjustFiltersForBST(filters) }).then((res) => {
            store.model = controller.adjustFiltersForBST(res.data, true);
            store.reportId = id;
            AppActions.getReportContents(id);
            store.saved();
        }).catch(res => API.ajaxHandler(store, res));
    },
};

store.dispatcherIndex = Dispatcher.register(store, (payload) => {
    const action = payload.action; // this is our action from handleViewAction

    switch (action.actionType) {
        case Actions.GET_REPORT_FILTERS:
            controller.getReportFilters(action.id);
            break;
        case Actions.SET_REPORT_FILTERS:
            controller.setReportFilters(action.id, action.filters);
            break;
        default:
    }
});


controller.store = store;
module.exports = controller.store;
