import BaseStore from './base/_store';
import data from './base/_data';

const store = Object.assign({}, BaseStore, {
    id: 'field',
    getFields() {
        return store.model && store.model.fields;
    },
});

const controller = {
    getFields: () => {
        if (store.isLoading) return;
        store.loading();
        // todo: maybe cache
        data.get(`${Project.api}settings`).then((res) => {
            store.model = res;
            store.loaded();
        }).catch(res => API.ajaxHandler(store, res));
        // setTimeout(() => {
        //     store.model = require('../../tests/pact/responses/fields.response');
        //     store.loaded();
        // });
    },
};

store.dispatcherIndex = Dispatcher.register(store, (payload) => {
    const action = payload.action; // this is our action from handleViewAction

    switch (action.actionType) {
        case Actions.GET_FIELDS:
            controller.getFields();
            break;
        case Actions.GET_REPORT:
        case Actions.GET_FOLDERS:
            if (!store.model) controller.getFields();
            break;
        default:
    }
});


controller.store = store;
module.exports = controller.store;
