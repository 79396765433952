/**
 * Created by kylejohnson on 21/08/2016.
 */
import withUserGroup from '../../../common/providers/withUserGroup';

const UserPage = class extends React.Component {
    displayName = 'UserPage';

    static propTypes ={
        id: propTypes.string.isRequired,
        userGroup: propTypes.object,
        userGroupLoading: propTypes.bool,
        setUserGroupProperty: propTypes.func.isRequired,
        userGroupSaving: propTypes.bool,
        saveUserGroup: propTypes.func.isRequired,
        deleteUserGroup: propTypes.func.isRequired,
    };

    static contextTypes = {
        router: propTypes.object.isRequired,
    };


    componentWillMount() {
        AppActions.getUserGroups();
    }

    onSave = (group) => {
        const { context: { router: { history: { replace } } } } = this;

        if (this.props.id) {
            toast('Group updated');
        } else {
            toast('Group created');
            $('.modal .close').click();
            replace(`/admin/groups/${group.id}`);
        }
    };

    onDelete = () => {
        const { context: { router: { history: { replace } } } } = this;
        $('.modal .close').click();
        replace('/admin/groups');
    };

    render() {
        const {
            props: {
                userGroup, userGroupLoading, setUserGroupProperty,
                setUserGroupLogo, userGroupSaving, saveUserGroup,
                deleteUserGroup, isValid, id,
            },
        } = this;
        return (
            <div className="mt-5">
                {/* Todo: Move to report detail page */}
                { userGroup && (
                    <div className="tab-content" id="pills-tabContent">
                        <div
                          className="tab-pane fade show active" id="pills-user" role="tabpanel"
                          aria-labelledby="pills-user-tab"
                        >

                            <div className="card card--report">
                                <ul className="nav justify-content-end">
                                    <li data-toggle="modal" data-target="#deleteGroupModal" className="nav-item nav-link">
                                        <i
                                          className="fas fa-trash" data-toggle="tooltip" data-placement="top"
                                          title="Delete"
                                        />
                                    </li>
                                </ul>
                                <h6 className="title">MANAGE USER GROUP</h6>
                                <div className="card-body">
                                    <form onSubmit={saveUserGroup} className="row">
                                        <div className="col-sm-6">
                                            {this.props.id && (
                                                <div className="form-group mb-5 mt-5 row">
                                                    <label className="col-sm-6 col-form-label">ID</label>
                                                    <div className="col-sm-6">
                                                        <input
                                                          value={userGroup.id}
                                                          readOnly
                                                          type="text" className="form-control"
                                                          placeholder="233"
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            <div className="form-group mb-5 mt-5 row">
                                                <label className="col-sm-6 col-form-label">Name</label>
                                                <div className="col-sm-6">
                                                    <input
                                                      value={userGroup.name}
                                                      onChange={e => setUserGroupProperty('name', e)}
                                                      type="text" className="form-control"
                                                      placeholder="Group Name"
                                                    />
                                                </div>
                                            </div>
                                            <div className="custom-control custom-checkbox">
                                                <Switch
                                                  checked={userGroup.whiteLabelEnabled}
                                                  onChange={e => setUserGroupProperty('whiteLabelEnabled', e)}
                                                  type="checkbox" className="custom-control-input" id="customCheck1"
                                                />
                                                <label className="pl-3 custom-control-label" htmlFor="customCheck1">Whitelabel Enabled</label>
                                            </div>
                                            {userGroup.whiteLabelEnabled ? (
                                                <React.Fragment>
                                                    {userGroup.whiteLabelUri ? (
                                                        <div className="form-group mb-5 mt-5 row">
                                                            <label className="col-sm-6 col-form-label">Domain</label>
                                                            <div className="col-sm-6">
                                                                <div className="flex-column">
                                                                    <input
                                                                      type="text"
                                                                      readOnly
                                                                      className="form-control"
                                                                      value={`http://${userGroup.whiteLabelUri}.${Project.hostName}`}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                    <div className="form-group mb-5 mt-5 row">
                                                        <label className="col-sm-6 col-form-label">Logo</label>
                                                        <div className="col-sm-6">
                                                            <div className="flex-column">
                                                                <input
                                                                  type="file"
                                                                  ref={c => this.fileInput = c}
                                                                  onChange={e => setUserGroupLogo(e.target.files[0], this.fileInput)}
                                                                  accept="image/*"
                                                                />
                                                                {userGroup.image ? (
                                                                    <div className="mt-2">
                                                                        <img
                                                                          src={userGroup.image} alt="User group logo" width={250}
                                                                        />
                                                                    </div>
                                                                ) : userGroup.hasImage ? (
                                                                    <div className="mt-2">
                                                                        <img
                                                                          src={`${Project.api}public/whitelabel/${userGroup.id}/logo`} alt="User group logo" width={250}
                                                                        />
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            ) : null}
                                        </div>
                                    </form>
                                </div>
                                <div className="card-footer">
                                    <button
                                      onClick={saveUserGroup} disabled={userGroupSaving || !isValid()} className="btn-lg btn--primary float-right"
                                      type="submit"
                                    >
                                        {userGroupSaving ? 'Saving' : 'Save'}
                                    </button>
                                </div>
                            </div>

                            <div
                              className="modal fade bd-example-modal-lg" id="deleteGroupModal" tabIndex="-1"
                              role="dialog" aria-labelledby="deleteGroupModalLabel" aria-hidden="true"
                            >
                                <div className="modal-dialog modal-lg" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="deleteGroupModalLabel">REMOVE USER</h5>
                                            <button
                                              type="button" className="close" data-dismiss="modal"
                                              aria-label="Close"
                                            >
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <p>
                                                Are you sure you wish to delete this group?
                                            </p>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn--secondary-outline" data-dismiss="modal">CANCEL</button>
                                            <button
                                              onClick={deleteUserGroup} disabled={userGroupSaving} type="button"
                                              className="btn--primary-outline"
                                            >
                                                Confirm
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
};

export default hot(module)(withUserGroup(UserPage));
